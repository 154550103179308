import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  CircularProgress,
  ClickAwayListener,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import CustomPrompt from "../CustomPrompt/CustomPrompt";
import CustomNewModal from "../CustomNewModal/CustomNewModal";
import HelpMenu from "../HelpMenu/HelpMenu";
import HeaderActionDropDown from "./HeaderActionDropDown";
import HeaderItem from "./HeaderItem";
import InitialsImage from "../InitialsImage/InitialsImage";
import LogoNova from "../../img/carePulseLogo.png";
import Chatbot from "../../img/Chatbot.svg";
import ActiveChatbot from "../../img/ActiveChatbot.svg";
import warningIcon from "../../img/warningIcon.svg";
import getToken from "../../Auth/getToken";
import composeEmail from "../../utils/composeEmail";
import removeToken from "../../Auth/removeToken";
import { useWindowDimension } from "../../CustomHooks/useWindowDimension";
import { removeUserSignUpInformationFromLocalStorage } from "../../containers/utils/removeUserSignupInformationFromLocalStoarge";
import {
  ACTION_DROPDOWN_ITEMS,
  HEADER_ITEMS,
  NON_LOGGED_IN_DROPDOWN,
} from "../../consts/accountActionDropDown";
import {
  ALL_APPOINTMENTS,
  DASHBOARD,
  FAVORITES,
  HIPPA_ROUTE,
  LIST_PRACTICE,
  LOGIN,
  PRIVACY_POLICY_ROUTE,
  SIGNUP,
  SPLASH,
  TERMS_AND_CONDITION,
} from "../../Auth/Routes";
import {
  CARE_PULSE_BROCHURE,
  CONTACT_CARE_PULSE,
  teamCarePulseEmail,
} from "../../consts/constant";
import { styles } from "./HeaderStyles";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import { DIM_GREY } from "../../consts/colors";

const Header = () => {
  const [showActionDropDown, setShowActionDropDown] = useState(false);
  const [customModalDetails, setCustomModalDetails] = useState({});
  const authToken = getToken();
  const [isUserLoggedIn, setUserLoggedIn] = useState(Boolean(authToken));
  const [anchorHelpPopover, setAnchorHelpPopover] = useState(null);
  const [openBrochure, setOpenBrochure] = useState(false);

  useEffect(() => {
    setUserLoggedIn(Boolean(authToken));
  }, [authToken]);

  const location = useLocation();
  const history = useHistory();
  const [width] = useWindowDimension();
  const onlyIncludeIcon =
    location.pathname === TERMS_AND_CONDITION ||
    location.pathname === HIPPA_ROUTE ||
    location.pathname === PRIVACY_POLICY_ROUTE;

  const { name, profileImage, email, country, city, state } = useSelector(
    (reduxState) => {
      return reduxState.userProfile.currentUserData;
    }
  );
  const { isLoggingOutUser } = useSelector((reduxState) => reduxState.login);

  const redirectToHome = () => {
    if (location.pathname !== DASHBOARD) {
      history.push(DASHBOARD, { scrollToTop: true });
    }
  };

  const redirectToDefaultPage = () => {
    if (location.pathname !== SPLASH) {
      if (location.pathname === SIGNUP) {
        removeUserSignUpInformationFromLocalStorage();
      }
      history.push(SPLASH);
    }
  };

  const toggleActionDropDown = () => {
    setShowActionDropDown((prev) => !prev);
  };

  const closeActionDropdown = () => {
    setShowActionDropDown(false);
  };

  const redirectToPage = (path) => {
    if (location.pathname !== path) {
      history.push(path);
    }
  };

  const headerNonLoggedInActions = useCallback(() => {
    if (location.pathname === LIST_PRACTICE) {
      return {
        showheaderDetails: true,
        headingText: "",
        buttonText: "",
        buttonIcon: "homeIcon",
        buttonClickHandler: () => {
          redirectToPage(SPLASH);
        },
      };
    }
    return {
      showheaderDetails: true,
      headingText: t("CARE_PULSE_PRACTICE_LIST"),
      onHeadingClick: () => {
        redirectToPage(LIST_PRACTICE);
      },
      buttonText: "",
      buttonClickHandler: () => {},
    };
  }, [location.pathname]);

  const headerItemClick = (path) => {
    if (location.pathname === path) {
      return;
    }
    history.push(path);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <AppBar position="sticky">
        {isUserLoggedIn ? (
          <Box sx={styles.appBarContainer} maxWidth="xl">
            <Toolbar>
              <Typography
                component="img"
                sx={styles.logoStyles}
                src={LogoNova}
                alt="Novadontics"
                onClick={redirectToHome}
              />
            </Toolbar>
            {!onlyIncludeIcon && (
              <Box sx={styles.otherOptionsContainer}>
                {width > 900 && (
                  <>
                    {HEADER_ITEMS.map((item, idx) => (
                      <HeaderItem
                        key={idx}
                        headerTitle={t(item.headerTitle)}
                        onClick={() => headerItemClick(item.redirectPath)}
                        largerText={item.largerText}
                        isSelected={location.pathname === item.redirectPath}
                        isImageView={item.redirectPath === ALL_APPOINTMENTS}
                        imgSrc={
                          item.redirectPath === ALL_APPOINTMENTS ? (
                            Chatbot
                          ) : (
                            <></>
                          )
                        }
                        selectedImgSrc={
                          item.redirectPath === ALL_APPOINTMENTS ? (
                            ActiveChatbot
                          ) : (
                            <></>
                          )
                        }
                        MuiIcon={
                          item.redirectPath === FAVORITES ? (
                            FavoriteBorderIcon
                          ) : (
                            <></>
                          )
                        }
                      />
                    ))}
                  </>
                )}
                <Box
                  style={styles.container}
                >
                  
                  <ClickAwayListener onClickAway={closeActionDropdown}>
                    <Box>
                      <Box
                        sx={styles.profileMenuContainer}
                        onClick={toggleActionDropDown}
                      >
                        {profileImage ? (
                          <Typography
                            component="img"
                            src={profileImage}
                            alt="profile-img"
                            sx={styles.profileImageStyle}
                          />
                        ) : name ? (
                          <Box sx={styles.defaultImageContainer}>
                            <InitialsImage
                              initials={(name || "").charAt(0)}
                              initialsImageCustomStyle={
                                styles.initialsImageCustomStyle
                              }
                              initialsTextCustomStyle={
                                styles.initialsTextCustomStyle
                              }
                            />
                          </Box>
                        ) : null}
                        <Box>
                          <Typography sx={styles.greetingText}>
                            {t("HELLO")}
                          </Typography>
                          <Typography sx={styles.profileText}>
                            {name}
                          </Typography>
                        </Box>
                      </Box>
                      {showActionDropDown && (
                        <HeaderActionDropDown
                          {...{
                            name,
                            profileImage,
                            email,
                            country,
                            city,
                            state,
                            closeActionDropdown,
                            setCustomModalDetails,
                          }}
                          dropdownItems={ACTION_DROPDOWN_ITEMS}
                        />
                      )}
                    </Box>
                  </ClickAwayListener>
                  {width <= 900 && <LanguageSelector />}
                </Box>
                {width > 900 && (
                  <Box sx={styles.logoutContainer}>
                    {isLoggingOutUser ? (
                      <CircularProgress
                        size={15}
                        thickness={8}
                        sx={styles.logOutLoaderStyles}
                      />
                    ) : (
                      <>
                        <LanguageSelector />
                        <PowerSettingsNewIcon
                          sx={styles.logoutHeaderIcon}
                          onClick={() => removeToken()}
                        />
                      </>
                    )}
                  </Box>
                )}
                {width <= 900 && isLoggingOutUser && (
                  <Box sx={styles.logoutContainer}>
                    <CircularProgress
                      size={15}
                      thickness={8}
                      sx={styles.logOutLoaderStyles}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={styles.appBarContainer} maxWidth="xl">
            <Toolbar>
              <Typography
                component="img"
                sx={styles.logoStyles}
                src={LogoNova}
                alt="Novadontics"
                onClick={redirectToDefaultPage}
              />
            </Toolbar>
            {headerNonLoggedInActions().showheaderDetails && (
              <Box sx={styles.itemContainer}>
                {!!headerNonLoggedInActions().headingText && (
                  <Box sx={styles.headingTextBox}>
                    <Typography
                      sx={styles.headerText}
                      onClick={() =>
                        headerNonLoggedInActions().onHeadingClick()
                      }
                    >
                      {headerNonLoggedInActions().headingText}
                    </Typography>
                  </Box>
                )}
                <>
                  {headerNonLoggedInActions().buttonIcon === "homeIcon" ? (
                    <Box style={styles.headerNotLoginContainer}>
                      <HomeOutlinedIcon
                        sx={{
                          ...styles.clickableIcon,
                        }}
                        onClick={() =>
                          headerNonLoggedInActions().buttonClickHandler()
                        }
                      />
                      <LanguageSelector/>
                    </Box>
                  ) : (
                    <>
                      {width > 900 ? (
                        <>
                          <Box
                            sx={{
                              ...styles.headingTextBox,
                              ...styles.middleBox,
                            }}
                          >
                            <Box sx={styles.btnContainer}>
                              <Typography
                                sx={styles.btnStyles}
                                onClick={() => redirectToPage(LOGIN)}
                              >
                                {t("LOGIN")}
                              </Typography>
                              <Typography sx={styles.slash}>/</Typography>
                              <Typography
                                sx={styles.btnStyles}
                                onClick={() => redirectToPage(SIGNUP)}
                              >
                                {t("SIGN_UP")}
                              </Typography>
                            </Box>
                          </Box>
    
                          <Box sx={{...styles.btnContainer,...styles.headingTextBox,...styles.helpContainer}}>
                            <Box>
                              <Typography
                                sx={styles.btnStyles}
                                onClick={(event) => {
                                  setAnchorHelpPopover(event.currentTarget);
                                }}
                              >
                                {t("HELP")}
                              </Typography>
                              <HelpMenu
                                open={Boolean(anchorHelpPopover)}
                                anchorEl={anchorHelpPopover}
                                handleClose={() => {
                                  setAnchorHelpPopover(null);
                                }}
                                items={[
                                  {
                                    name: t("CONTACT_CARE_PULSE"),
                                    handleClose: () => {
                                      setAnchorHelpPopover(null);
                                      composeEmail(teamCarePulseEmail);
                                    },
                                  },
                                  {
                                    name: t("CARE_PULSE_BROCHURE"),
                                    handleClose: () => {
                                      setAnchorHelpPopover(null);
                                      setOpenBrochure(true);
                                    },
                                  },
                                ]}
                              />
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <ClickAwayListener onClickAway={closeActionDropdown}>
                          <Box>
                            <Box
                              sx={styles.menuIconBox}
                              onClick={toggleActionDropDown}
                            >
                              <MenuIcon sx={styles.menuIcon} />
                            </Box>
                            {showActionDropDown && (
                              <HeaderActionDropDown
                                {...{
                                  closeActionDropdown,
                                  setCustomModalDetails,
                                }}
                                dropdownItems={NON_LOGGED_IN_DROPDOWN}
                                openBrochure={() => {
                                  setOpenBrochure(true);
                                }}
                              />
                            )}
                          </Box>
                        </ClickAwayListener>
                      )}
                      <LanguageSelector />
                    </>
                  )}
                </>
              </Box>
            )}
          </Box>
        )}
      </AppBar>
      {customModalDetails.showModal && (
        <CustomPrompt
          open={customModalDetails.showModal}
          fullWidth
          showLoader={isLoggingOutUser}
          heading="Caution"
          modalIcon={warningIcon}
          modalIconAlt="warning"
          descriptionLineOne={customModalDetails.heading}
          descriptionLineTwo={customModalDetails.descriptionLine1}
          buttonOneText="Confirm"
          buttonTwoText="Cancel"
          onButtonOneClick={customModalDetails.confirmDeleteHandler}
          onButtonTwoClick={customModalDetails.closeModalHandler}
          closeModalHandler={customModalDetails.closeModalHandler}
        />
      )}
      <CustomNewModal
        openModal={openBrochure}
        fullWidth
        maxWidth="xl"
        closeModalHandler={() => {
          setOpenBrochure(false);
        }}
        includeCloseButton
      >
        <Box sx={styles.brochureContainer}>
          <Typography sx={styles.brochureHeadingStyle}>
            {t("CARE_PULSE_BROCHURE")}
          </Typography>
        </Box>
        <Typography
          component={"iframe"}
          src={
            "https://s3.amazonaws.com/novadontics-uploads/carepulsedentistbrochure-1724603968301.pdf"
          }
          sx={styles.pdfStyle}
        ></Typography>
      </CustomNewModal>
    </React.Fragment>
  );
};

export default Header;
