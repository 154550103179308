import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  currentUserData: {},
  showUserProfileLoader: false,
  isErrorLoadingUserDetails: false,
  tab: 0,
  isEditButtonClicked: false,
};

const setCurrentUserData = (state, action) => {
  return updateObject(state, {
    currentUserData: action.payload,
  });
};

const setUserProfileLoader = (state, action) => {
  return updateObject(state, {
    showUserProfileLoader: action.payload,
  });
};

const errorLoadingUserDetails = (state, action) => {
  return updateObject(state, {
    isErrorLoadingUserDetails: action.payload,
  });
};

const setTab = (state, action) => {
  return updateObject(state, {
    tab: action.payload,
  });
};

const resetTab = (state, action) => {
  return updateObject(state, {
    tab: 0,
  });
};

const editDetailsHandler = (state, action) => {
  return updateObject(state, {
    isEditButtonClicked: action.payload,
  });
};

const userProfileReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.CURRENT_USER_DATA:
      return setCurrentUserData(state, action);
    case types.SHOW_USER_PROFILE_LOADER:
      return setUserProfileLoader(state, action);
    case types.IS_ERROR_FETCHING_USER_DETAILS:
      return errorLoadingUserDetails(state, action);
    case types.SET_TAB:
      return setTab(state, action);
    case types.RESET_TAB:
      return resetTab(state, action);
    case types.EDIT_DETAILS_HANDLER:
      return editDetailsHandler(state, action);
    default:
      return state;
  }
};
export default userProfileReducers;
