import { DIM_GREY, LIGHTER_GREY, LIGHT_GREY } from "../../consts/colors";

export const styles = {
  menuItems: {
    display: "flex",
    texAlign: "left",
    flexDirection: "column",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  menuPaperStyles: {
    marginTop: "5px",
  },
  dividerStyle: {
    marginTop: "0px",
    width: "100%",
    "&:hover": {
      backgroundColor: "white!important",
    },
  },
  menuText: {
    color: DIM_GREY,
    fontWeight: 600,
    fontSize: "14px",
    paddingTop: "8px",
    paddingBottom: "8px",
    cursor: "pointer",
    texAlign: "left",
  },
};
