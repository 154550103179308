import React from "react";
import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";

import classes from "./Toast.module.css";
import { styles } from "./ToastStyles";

const Toast = (props) => {
  const {
    open,
    closeHandler,
    alertMessage,
    severity,
    autoHideDuration,
    customToastStyle,
    smallScreen,
  } = props;

  return (
    <Snackbar
      open={open}
      className={`${classes.snackbar} ${
        smallScreen ? classes.smallScreen : ""
      }`}
      autoHideDuration={autoHideDuration}
      onClose={closeHandler}
    >
      <Alert
        severity={severity || ""}
        icon={false}
        sx={{
          ...(severity === "success"
            ? styles.toastSuccessMessage
            : styles.toastMessage),
          ...customToastStyle,
        }}
      >
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

Toast.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  alertMessage: PropTypes.string,
  severity: PropTypes.string,
  autoHideDuration: PropTypes.number,
  customToastStyle: PropTypes.object,
  smallScreen: PropTypes.bool,
};

export default Toast;
