import React from "react";
import PropTypes from "prop-types";
import { Box, Divider, Grid, MenuItem, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ReviewsIcon from "@mui/icons-material/Reviews";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import { useWindowDimension } from "../../CustomHooks/useWindowDimension";
import { styles } from "./HeaderStyles";
import { useTranslation } from "react-i18next";

const RenderDropDownField = ({
  tabDataField,
  onClickDataItem,
  index,
  dropdownItems,
}) => {
  const [width] = useWindowDimension();
  const {t} = useTranslation()

  const renderMUIIcon = (tabField, muiIconStyles) => {
    if (tabField.useMUIHomeIcon) {
      return (
        <HomeOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUIFavIcon) {
      return (
        <FavoriteBorderIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUIFamilyIcon) {
      return (
        <PeopleOutlineOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUIChangePassIcon) {
      return (
        <VpnKeyOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUISupportIcon) {
      return (
        <ForumOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUIAccountIcon) {
      return (
        <AccountCircleOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUIEditAccountIcon) {
      return (
        <BorderColorOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMUILogoutIcon) {
      return (
        <LogoutIcon
          sx={{
            ...(tabField.lightIconColor ? styles.lightIcon : muiIconStyles),
            ...(width <= 900 ? styles.logoutIcon : {}),
          }}
        />
      );
    }
    if (tabField.useMUIReviewIcon) {
      return (
        <ReviewsIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
    if (tabField.useMuiInsurnanceIcon) {
      return (
        <MedicalInformationOutlinedIcon
          sx={tabField.lightIconColor ? styles.lightIcon : muiIconStyles}
        />
      );
    }
  };

  if (width <= 900 && tabDataField.showOnSmallerScreen) {
    return (
      <>
        <MenuItem
          sx={{
            ...styles.dropDownItem,
          }}
          onClick={(event) => onClickDataItem(event, tabDataField)}
          id={tabDataField.id}
        >
          <Grid container>
            <Grid item xs={12}>
              {tabDataField.useMUILogoutIcon ? (
                <Box
                  sx={{
                    ...styles.fullWidthBox,
                    ...styles.logoutBoxForMobileScreen,
                  }}
                >
                  <Box
                    sx={{
                      ...styles.field,
                      ...styles.mobileLogoutBox,
                    }}
                  >
                    {renderMUIIcon(tabDataField, styles.muiIconStyles)}
                    <Typography
                      sx={{
                        ...styles.fieldText,
                        ...styles.mobileLogoutText,
                      }}
                    >
                      {t(tabDataField.actionText)}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Grid container>
                  {tabDataField.noImage ? null : (
                    <Grid item xs={3} sx={styles.actionItemImage}>
                      {tabDataField.iconSrc ? (
                        <Typography
                          component="img"
                          src={tabDataField.iconSrc}
                          alt={tabDataField.altText}
                          width={24}
                          height={24}
                        />
                      ) : (
                        renderMUIIcon(tabDataField, styles.muiIconStyles)
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={tabDataField.noImage ? 12 : 9}
                    sx={{
                      ...styles.actionItemContent,
                      ...(tabDataField.noImage ? styles.rowWithoutImage : {}),
                    }}
                  >
                    <Typography sx={styles.actionTextStyles}>
                    {t(tabDataField.actionText)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </MenuItem>
        {!tabDataField.useMUIAccountIcon &&
          dropdownItems.length - 2 > index && <Divider sx={styles.divider} />}
      </>
    );
  }
  if (!tabDataField.showOnSmallerScreen && !tabDataField.containTwoItem) {
    return (
      <>
        {width <= 900 && tabDataField.hideOnSmallerScreen ? null : (
          <>
            <MenuItem
              sx={{
                ...styles.dropDownItem,
              }}
              onClick={(event) => onClickDataItem(event, tabDataField)}
              id={tabDataField.id}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    {tabDataField.noImage ? null : (
                      <Grid item xs={3} sx={styles.actionItemImage}>
                        {tabDataField.iconSrc ? (
                          <Typography
                            component="img"
                            src={tabDataField.iconSrc}
                            alt={tabDataField.altText}
                            width={24}
                            height={24}
                          />
                        ) : (
                          renderMUIIcon(tabDataField, styles.muiIconStyles)
                        )}
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={tabDataField.noImage ? 12 : 9}
                      sx={{
                        ...styles.actionItemContent,
                        ...(tabDataField.noImage ? styles.rowWithoutImage : {}),
                      }}
                    >
                      <Typography sx={styles.actionTextStyles}>
                      {t(tabDataField.actionText)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
            {(tabDataField.showDivider || dropdownItems.length - 2 > index) && (
              <Divider sx={styles.divider} />
            )}
          </>
        )}
      </>
    );
  }
  if (tabDataField.containTwoItem) {
    return (
      <Box sx={{ ...styles.singleRow }}>
        {tabDataField.actions.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              ...(width <= 900 && item.hideOnSmallerScreen
                ? styles.hideBox
                : styles.fullWidthBox),
              ...(width > 900 ? styles.halfBox : {}),
            }}
          >
            {width <= 900 && item.hideOnSmallerScreen ? null : (
              <Box
                key={idx}
                sx={{
                  ...styles.field,
                  ...(width <= 900 && item.useMUILogoutIcon
                    ? styles.mobileLogoutBox
                    : {}),
                }}
                onClick={(event) => onClickDataItem(event, item)}
              >
                {item.iconSrc ? (
                  <Typography
                    component="img"
                    src={tabDataField.iconSrc}
                    alt={tabDataField.altText}
                    width={24}
                    height={24}
                  />
                ) : (
                  renderMUIIcon(item, styles.muiIconStyles)
                )}
                <Typography
                  sx={{
                    ...styles.fieldText,
                    ...(width <= 900 && item.useMUILogoutIcon
                      ? styles.mobileLogoutText
                      : {}),
                  }}
                >
                  {t(item.actionText)}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  }
  return <></>;
};

RenderDropDownField.propTypes = {
  tabDataField: PropTypes.object,
  onClickDataItem: PropTypes.func,
  index: PropTypes.number,
  dropdownItems: PropTypes.array,
};

export default RenderDropDownField;
