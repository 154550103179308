export const LOGIN_USER = "LOGIN_USER";
export const IS_USER_SUCCESSFULLY_LOGGED_IN = "IS_USER_SUCCESSFULLY_LOGGED_IN";
export const IS_LOGIN_API_FAILED = "REQUEST_ERROR";
export const LOGIN_ERROR_MESSAGE = "LOGIN_ERROR_MESSAGE";
export const INVALID_CREDENTIALS = "INVALID_CREDENTIALS";
export const SESSION_TIME_OUT = "SESSION_TIME_OUT";
export const LOGIN_REDIRECT_ROUTE = "LOGIN_REDIRECT_ROUTE";
export const REDIRECT_USER_FROM_DEFAULT_PAGE =
  "REDIRECT_USER_FROM_DEFAULT_PAGE";
export const RESET_REDIRECT_USER_FROM_DEFAULT_PAGE =
  "RESET_REDIRECT_USER_FROM_DEFAULT_PAGE";
export const IS_SIGNING_IN_USER = "IS_SIGNING_IN_USER";
export const LOGGING_OUT_USER = "LOGGING_OUT_USER";
export const NEW_USER_IS_REGISTERED = "NEW_USER_IS_REGISTERED";
export const ACCOUNT_IS_RESTORED = "ACCOUNT_IS_RESTORED";
