import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isLoadingSearchResults: false,
  clinicsList: null,
  specialityList: null,
  providersList: null,
  currentLocation: {},
  isLoadingCurrentLocation: false,
  isErrorCurrentLocation: false,
  selectedLocation: {},
  isGettingCurrentLocationInfo: false,
  currentLocationInfo: {},
};

const loadingSearchResults = (state, action) => {
  return updateObject(state, {
    isLoadingSearchResults: action.payload,
  });
};

const globalSearch = (state, action) => {
  return updateObject(state, {
    clinicsList: action.payload?.clinics,
    dentalConditionsList: action.payload?.dentalConditions,
    providersList: action.payload?.providers,
    servicesList: action.payload?.services,
    specialityList: action.payload?.speciality,
  });
};

const emptySearchResults = (state, action) => {
  return updateObject(state, {
    clinicsList: null,
    dentalConditionsList: null,
    providersList: null,
    servicesList: null,
    specialityList: null,
  });
};

const loadingCurrentLocation = (state, action) => {
  return updateObject(state, {
    isLoadingCurrentLocation: action.payload,
  });
};

const errorCurrentLocation = (state, action) => {
  return updateObject(state, {
    isErrorCurrentLocation: action.payload,
  });
};

const setCurrentLocation = (state, action) => {
  return updateObject(state, {
    currentLocation: action.payload,
  });
};

const deleteCurrentLocation = (state, action) => {
  return updateObject(state, {
    currentLocation: {},
  });
};

const setSelectedLocation = (state, action) => {
  return updateObject(state, {
    selectedLocation: action.payload,
  });
};

const resetSelectedLocation = (state, action) => {
  return updateObject(state, {
    selectedLocation: {},
  });
};

const setIsGettingCurrentLocationInfo = (state, action) => {
  return updateObject(state, {
    isGettingCurrentLocationInfo: action.payload,
  });
};

const setCurrentLocationInfo = (state, action) => {
  return updateObject(state, {
    currentLocationInfo: action.payload,
  });
};

const globalSearchReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_LOADING_SEARCH_RESULTS:
      return loadingSearchResults(state, action);
    case types.GLOBAL_SEARCH:
      return globalSearch(state, action);
    case types.EMPTY_SEARCH_RESULTS:
      return emptySearchResults(state, action);
    case types.SET_CURRENT_LOCATION:
      return setCurrentLocation(state, action);
    case types.DELETE_CURRENT_LOCATION:
      return deleteCurrentLocation(state, action);
    case types.IS_LOADING_CURRENT_LOCATION:
      return loadingCurrentLocation(state, action);
    case types.IS_ERROR_CURRENT_LOCATION:
      return errorCurrentLocation(state, action);
    case types.SET_SELECTED_LOCATION:
      return setSelectedLocation(state, action);
    case types.DELETE_SELECTED_LOCATION:
      return resetSelectedLocation(state, action);
    case types.IS_GETTING_CURRENT_LOCATION_INFO:
      return setIsGettingCurrentLocationInfo(state, action);
    case types.CURRENT_LOCATION_INFO:
      return setCurrentLocationInfo(state, action);
    default:
      return state;
  }
};

export default globalSearchReducers;
