import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { getItem } from "./Auth/localStorageService";
import { store } from "./Store/store";
import i18n from "i18next";
import { languageKey } from "./components/LanguageSelector/LanguageSelectorConstant";
import translationEN from "./locales/en/translation.json";
import translationSP from "./locales/sp/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: getItem("language") || languageKey.en,
    fallbackLng: languageKey.en,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: translationEN,
      },
      sp: {
        translation: translationSP,
      },
    },
  });

store.subscribe(() => {
  const state = store.getState();
  const language = state.languageChange.language;
  i18n.changeLanguage(language);
});

export default i18n;
