import * as types from "./types";
import NovaAPI from "../../Axios/NovaAPI";
import { SUCCESS_STATUS } from "../../Auth/statusCodes";
import { urlService } from "../../utils/urlService";

export const loadingSearchResults = (payload) => {
  return {
    type: types.IS_LOADING_SEARCH_RESULTS,
    payload,
  };
};

export const emptySearchResults = () => {
  return {
    type: types.EMPTY_SEARCH_RESULTS,
  };
};

export const setSelectedLocation = (payload) => {
  return {
    type: types.SET_SELECTED_LOCATION,
    payload,
  };
};

export const resetSelectedLocation = () => {
  return {
    type: types.DELETE_SELECTED_LOCATION,
  };
};

export const globalSearch = (
  searchText,
  latitude,
  longitude,
  userId,
  favorite,
  country
) => {
  return (dispatch) => {
    const requestedParams = {
      userId,
      favorite,
    };
    dispatch(loadingSearchResults(true));
    NovaAPI.get(
      `/v4/globalSearch?${urlService.objectToQueryString(requestedParams)}`,
      {
        headers: {
          searchText,
          latitude,
          longitude,
          country,
        },
      }
    )
      .then((res) => {
        dispatch(loadingSearchResults(false));
        if (res.status === SUCCESS_STATUS) {
          dispatch({
            type: types.GLOBAL_SEARCH,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch(loadingSearchResults(false));
      });
  };
};

export const loadingCurrentLocation = (payload) => {
  return {
    type: types.IS_LOADING_CURRENT_LOCATION,
    payload,
  };
};

export const errorCurrentLocation = (payload) => {
  return {
    type: types.IS_ERROR_CURRENT_LOCATION,
    payload,
  };
};

export const deleteCurrentLocation = () => {
  return {
    type: types.DELETE_CURRENT_LOCATION,
  };
};

export const setIsGettingCurrentLocationInfo = (payload) => {
  return {
    type: types.IS_GETTING_CURRENT_LOCATION_INFO,
    payload,
  };
};

export const setCurrentLocationInfo = (payload) => {
  return {
    type: types.CURRENT_LOCATION_INFO,
    payload,
  };
};

export const setCurrentLocation = (position) => {
  return (dispatch) => {
    if (position) {
      dispatch({
        type: types.SET_CURRENT_LOCATION,
        payload: { latitude: position.latitude, longitude: position.longitude },
      });
      dispatch(errorCurrentLocation(false));
    } else {
      dispatch(loadingCurrentLocation(true));
      dispatch(deleteCurrentLocation());
      window.navigator.geolocation.getCurrentPosition(
        (navigatorPosition) => {
          dispatch(errorCurrentLocation(false));
          const { latitude, longitude } = navigatorPosition.coords;
          dispatch({
            type: types.SET_CURRENT_LOCATION,
            payload: { latitude, longitude },
          });
          dispatch(loadingCurrentLocation(false));
        },
        () => {
          dispatch(loadingCurrentLocation(false));
          dispatch(errorCurrentLocation(true));
          dispatch(deleteCurrentLocation());
        }
      );
    }
  };
};
