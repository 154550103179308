import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import LocationSearch from "../LocationSearch/LocationSearch";
import { modalCommonStyles } from "../../Assests/styles/commonStyles";
import { styles } from "./CustomModalStyles";

const CustomModal = (props) => {
  const {
    modalType,
    open,
    closeModalHandler,
    icon,
    iconAltText,
    heading,
    descriptionLine1,
    descriptionLine2,
    declineButtonText,
    confirmButtonText,
    confirmHandler,
    hideButtons,
    customModalHeading,
    customDescriptionOneStyle,
    cancelModalButtonstyles,
    confirmModalButtonstyles,
    customCancelButtonContainer,
    customConfirmButtonContainer,
    fullModalButton,
    customModalWarningImageStyles,
    omitCloseButton,
    showLocationSearchComponent,
    selectedLocation,
    showLoader,
  } = props;

  const [isShowSuggestedLocations, setIsShowSuggestedLocations] =
    useState(false);

  const getShowSuggestedLocationsState = (showSuggestedLocations) => {
    setIsShowSuggestedLocations(showSuggestedLocations);
  };

  useEffect(() => {
    if (showLocationSearchComponent) {
      if (Object.keys(selectedLocation).length) {
        closeModalHandler();
      }
    }
  }, [selectedLocation]);

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, false);
    return () => {
      window.removeEventListener("keyup", handleKeyUp, false);
    };
  }, []);

  const handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        if (omitCloseButton) {
          return;
        }
        closeModalHandler();
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  };

  useEffect(() => {
    return () => {
      setIsShowSuggestedLocations(false);
    };
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      sx={modalCommonStyles.modal}
      open={open}
    >
      <Box
        sx={{
          ...styles.modalContainer,
          ...(isShowSuggestedLocations ? styles.conditionalHeight : {}),
        }}
      >
        {showLoader ? (
          <Box sx={modalCommonStyles.loaderContainer}>
            <CircularProgress sx={modalCommonStyles.loader} />
          </Box>
        ) : (
          <>
            {omitCloseButton ? null : (
              <Box sx={styles.buttonContainer}>
                <Button onClick={closeModalHandler}>
                  <ClearIcon sx={styles.closeIcon} />
                </Button>
              </Box>
            )}
            <Box>
              <Box sx={styles.modalWarningImage}>
                <Typography
                  component="img"
                  src={icon}
                  alt={iconAltText}
                  width={150}
                  height={130}
                  sx={{
                    ...styles.imageStyles,
                    ...customModalWarningImageStyles,
                  }}
                />
              </Box>
            </Box>
            <Typography sx={{ ...styles.modalHeading, ...customModalHeading }}>
              {heading}
            </Typography>
            <Box sx={styles.locatonBoxContainer}>
              <Typography
                sx={{
                  ...styles.modalDescription,
                  ...customDescriptionOneStyle,
                }}
              >
                {descriptionLine1}
              </Typography>
              {!!descriptionLine2 && (
                <Typography sx={styles.modalDescription}>
                  {descriptionLine2}
                </Typography>
              )}
            </Box>
            {showLocationSearchComponent && (
              <Box sx={styles.locatonBoxContainer}>
                <LocationSearch
                  preventModal
                  {...{ getShowSuggestedLocationsState }}
                />
              </Box>
            )}
            {!hideButtons && (
              <Grid container sx={styles.modalButtonContainer} spacing={3}>
                {declineButtonText && (
                  <Grid
                    item
                    xs={6}
                    sx={{
                      ...styles.modalButtonItem,
                      ...customCancelButtonContainer,
                    }}
                  >
                    {modalType === "confirm" && (
                      <Button
                        sx={{
                          ...styles.modalButton,
                          ...styles.stayButton,
                          ...cancelModalButtonstyles,
                        }}
                        onClick={closeModalHandler}
                      >
                        {declineButtonText}
                      </Button>
                    )}
                  </Grid>
                )}
                <Grid
                  item
                  xs={fullModalButton ? 12 : 6}
                  sx={{
                    ...styles.modalButtonItem,
                    ...customConfirmButtonContainer,
                  }}
                >
                  <Button
                    sx={{
                      ...styles.modalButton,
                      ...styles.leaveButton,
                      ...confirmModalButtonstyles,
                    }}
                    onClick={confirmHandler}
                  >
                    {confirmButtonText}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </Dialog>
  );
};

CustomModal.propTypes = {
  modalType: PropTypes.string,
  open: PropTypes.bool,
  closeModalHandler: PropTypes.func,
  icon: PropTypes.string,
  iconAltText: PropTypes.string,
  heading: PropTypes.string,
  descriptionLine1: PropTypes.string,
  descriptionLine2: PropTypes.string,
  declineButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmHandler: PropTypes.func,
  hideButtons: PropTypes.bool,
  customModalHeading: PropTypes.object,
  customDescriptionOneStyle: PropTypes.object,
  cancelModalButtonstyles: PropTypes.object,
  confirmModalButtonstyles: PropTypes.object,
  customCancelButtonContainer: PropTypes.object,
  customConfirmButtonContainer: PropTypes.object,
  fullModalButton: PropTypes.bool,
  customModalWarningImageStyles: PropTypes.object,
  omitCloseButton: PropTypes.bool,
  showLocationSearchComponent: PropTypes.bool,
  selectedLocation: PropTypes.object,
};

export default CustomModal;
