import history from "../history";
import { removeItem } from "./localStorageService";
import { LOGIN } from "./Routes";
import { store } from "../Store/store";
import {
  setUserSessionTimeOut,
  isSuccessfullyLoggedIn,
} from "../Store/Login/loginActions";
import { destroyToken } from "../utils/apiService/accountApiService";

export default async function logUserOut() {
  await destroyToken();
  store.dispatch(setUserSessionTimeOut(true));
  removeItem("loginToken");
  store.dispatch(isSuccessfullyLoggedIn(false));
  history.push(LOGIN);
}
