import * as types from './types'
import { updateObject } from "../utility";
import { getItem } from "../../Auth/localStorageService";

const initialState = {
  language: getItem("language") || "en",
};
const setLanguageChange = (state, action) => {
  return updateObject(state, {
   language: action.payload,
  });
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE:
      return setLanguageChange(state,action)
    default:
      return state;
  }
};
export default languageReducer;

