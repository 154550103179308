import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, ClickAwayListener, MenuList } from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";

import CustomInputBase from "../CustomInputBase/CustomInputBase";
import SingleListItem from "../SingleListItem/SingleListItem";
import CustomModal from "../CustomModal/CustomModal";
import LocationPrompt from "../../img/LocationPrompt.svg";
import {
  setCurrentLocation,
  setSelectedLocation,
  resetSelectedLocation,
} from "../../Store/GlobalSearch/globalSearchActions";

import { languageArray } from "../LanguageSelector/LanguageSelectorConstant";
import { styles } from "./LocationSearchStyles";

const LocationSearch = (props) => {
  const {
    fillLocationDetails,
    setLocationDetails,
    inputBaseClass,
    searchTrayContainerClass,
    customLocationDescription,
    allowManualAddress,
    setValue,
    startIconStyles,
    internalLocationSearch,
    isRequire,
    defaultValue,
    preventModal,
    getShowSuggestedLocationsState,
    searchTrayCustomStyles,
    locationSearchBoxContainer,
    showEndIcon,
  } = props;

  const [showSuggestedLocations, setShowSuggestedLocations] = useState(false);
  const [searchedLocation, setSearchedLocation] = useState("");
  const [suggestedLocations, setSuggestedLocations] = useState([]);
  const [showLocationDetail, setShowLocationDetail] = useState(true);
  const [showLocationPrompt, setShowLocationPrompt] = useState(false);
  const [defaultSearchedLocation, setDefaultSearchedLocation] = useState("");
  const [internalCurrentLocation, setInternalCurrentLoation] = useState({});
  const [internalSelectedLocation, setInternalSelectedLocation] = useState({});
  const [errorInternalCurrentLocation, setErrorInternalCurrentLocation] =
    useState(false);
  const [loadingInternalCurrentLocation, setLoadingInternalCurrentLocation] =
    useState(false);
  const { currentLocation, isLoadingCurrentLocation, selectedLocation } =
    useSelector((state) => {
      return state.globalSearch;
    });
    const language=useSelector((state)=>state.languageChange.language)

  const dispatch = useDispatch();

  const getLanguage=()=>{
   const item= languageArray.find((item)=>{
      return item.key===language
    })
    return item.locale
  }
  
  useEffect(() => {
    if (getShowSuggestedLocationsState) {
      getShowSuggestedLocationsState(showSuggestedLocations);
    }
  }, [showSuggestedLocations]);

  const hideSuggestedLocation = () => {
    setShowSuggestedLocations(false);
  };

  const cleanSearchResults = () => setSuggestedLocations([]);

  const setLocationDetailFromLatLng = () => {
    const { latitude, longitude } = internalLocationSearch
      ? internalCurrentLocation
      : currentLocation || {};
    if (!latitude || !longitude) {
      internalLocationSearch
        ? setInternalSelectedLocation({})
        : dispatch(setSelectedLocation({}));
      setSearchedLocation("");
      return;
    }

    const latlng = {
      lat: latitude,
      lng: longitude,
    };
    window.google &&
      new window.google.maps.Geocoder()
        .geocode({ location: latlng })
        .then((response) => {
          setSearchedLocation("");
          internalLocationSearch
            ? setInternalSelectedLocation(response.results[0])
            : dispatch(setSelectedLocation(response.results[0]));
          fillLocationDetails && getLocationBasicDetails(response.results);
        })
        .catch((error) => console.log(error));
  };

  const setRelatedSearchLocation = () => {
    const displaySuggestions = function (predictions, status) {
      if (
        status != window.google.maps.places.PlacesServiceStatus.OK ||
        !predictions
      ) {
        return;
      }
      setSuggestedLocations(predictions || []);
      setShowSuggestedLocations(true);
    };
    window.google &&
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: searchedLocation,
          language:getLanguage()
        },
        displaySuggestions
      );
  };
  
  const handleInternalCurrentLocation = () => {
    setLoadingInternalCurrentLocation(true);
    setErrorInternalCurrentLocation(false);
    window.navigator.geolocation.getCurrentPosition(
      (navigatorPosition) => {
        const { latitude, longitude } = navigatorPosition.coords;
        setErrorInternalCurrentLocation(false);
        setInternalCurrentLoation({ latitude, longitude });
        setLoadingInternalCurrentLocation(false);
      },
      () => {
        setLoadingInternalCurrentLocation(false);
        setErrorInternalCurrentLocation(true);
      }
    );
  };

  const onStartIconClick = () => {
    internalLocationSearch
      ? setInternalSelectedLocation({})
      : dispatch(resetSelectedLocation());
    internalLocationSearch
      ? handleInternalCurrentLocation()
      : dispatch(setCurrentLocation());
    setShowLocationDetail(true);
    hideSuggestedLocation();
  };

  const {t} = useTranslation()

  const onClickAway = () => {
    hideSuggestedLocation();
    setShowLocationDetail(true);
    cleanSearchResults();
    !allowManualAddress && setSearchedLocation("");
  };

  useEffect(() => {
    (internalLocationSearch
      ? !loadingInternalCurrentLocation
      : !isLoadingCurrentLocation) &&
      !Object.keys(
        internalLocationSearch ? internalSelectedLocation : selectedLocation
      ).length &&
      setLocationDetailFromLatLng();
  }, [isLoadingCurrentLocation, loadingInternalCurrentLocation, window.google]);

  useEffect(() => {
    defaultValue && setDefaultSearchedLocation(defaultValue);
    defaultValue && setSearchedLocation(defaultValue);
  }, []);

  useEffect(() => {
    if (searchedLocation && searchedLocation !== defaultSearchedLocation) {
      setRelatedSearchLocation();
      setDefaultSearchedLocation("");
    }
  }, [searchedLocation]);

  useEffect(() => {
    internalLocationSearch &&
      !loadingInternalCurrentLocation &&
      errorInternalCurrentLocation &&
      setShowLocationPrompt(true);
  }, [
    isLoadingCurrentLocation,
    errorInternalCurrentLocation,
    loadingInternalCurrentLocation,
  ]);

  const hideLocationPermissionPrompt = () => {
    setShowLocationPrompt(false);
  };

  const getLocationBasicDetails = (results) => {
    let locationDetail = {};
    if (results[0]) {
      for (var i = 0; i < results[0].address_components.length; i++) {
        if (results[0].address_components[i].types[0] === "locality") {
          locationDetail.city = results[0].address_components[i]?.long_name;
        }
        if (
          results[0].address_components[i].types[0] ===
          "administrative_area_level_1"
        ) {
          locationDetail.state = results[0].address_components[i]?.long_name;
        }
        if (results[0].address_components[i].types[0] === "country") {
          locationDetail.country = results[0].address_components[i]?.long_name;
        }
        if (results[0].address_components[i].types[0] === "postal_code") {
          locationDetail.postalcode =
            results[0].address_components[i]?.long_name;
        }
      }
      setLocationDetails && setLocationDetails({ ...locationDetail });
    }
  };

  const setLatLngFromAddress = (address) => {
    var geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        address: address.description,
      },
      function (results, status) {
        if (
          status != window.google.maps.places.PlacesServiceStatus.OK ||
          !results
        ) {
          return;
        }
        let place = results[0];
        internalLocationSearch
          ? setInternalCurrentLoation({
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            })
          : dispatch(
              setCurrentLocation({
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
              })
            );
        fillLocationDetails && getLocationBasicDetails(results);
      }
    );
  };

  const getDetailFromSelectedLocation = () => {
    return internalLocationSearch
      ? internalSelectedLocation.formatted_address ||
          internalSelectedLocation.description ||
          ""
      : selectedLocation.formatted_address ||
          selectedLocation.description ||
          "";
  };

  useEffect(() => {
    setValue &&
      setValue(
        showLocationDetail
          ? (allowManualAddress && searchedLocation) ||
              getDetailFromSelectedLocation()
          : searchedLocation
      );
  }, [searchedLocation, selectedLocation, internalSelectedLocation]);

  useEffect(() => {
    return () => {
      setShowSuggestedLocations(false);
      setSearchedLocation("");
      setSuggestedLocations([]);
      setShowLocationDetail(true);
      setDefaultSearchedLocation("");
      setInternalCurrentLoation({});
      setInternalSelectedLocation({});
      setLoadingInternalCurrentLocation(false);
      setErrorInternalCurrentLocation(false);
      setShowLocationPrompt(false);
    };
  }, []);

  return (
    <Box
      sx={{
        ...(preventModal
          ? styles.modalSearchTrayContainer
          : styles.locationSearchRoot),
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Box
          sx={{
            ...styles.searchInputAndTrayContainer,
            ...locationSearchBoxContainer,
          }}
        >
          <CustomInputBase
            customInputStyle={{
              ...inputBaseClass,
              ...(preventModal ? styles.modalSearchTray : {}),
            }}
            showStartIcon={!showEndIcon}
            startIcon={<LocationOnOutlined />}
            onStartIconClick={onStartIconClick}
            setValue={(value) => {
              setSearchedLocation(value);
            }}
            showEndIcon={showEndIcon}
            endIcon={<LocationOnOutlined />}
            onEndIconClick={onStartIconClick}
            endIconStyles={styles.endIconStyles}
            onFocus={() => setShowLocationDetail(false)}
            isRequire={isRequire}
            startIconStyles={startIconStyles}
            value={
              showLocationDetail
                ? (allowManualAddress && searchedLocation) ||
                  getDetailFromSelectedLocation()
                : searchedLocation
            }
            placeholder={t("STREET_ADDRESS")}
          />
          <Box sx={{ ...styles.rootContainer, ...searchTrayContainerClass }}>
            {showSuggestedLocations && (
              <MenuList
                sx={{
                  ...styles.searchTray,
                  ...(preventModal ? styles.modalSearchTrayPosition : {}),
                  ...searchTrayCustomStyles,
                }}
              >
                <Box>
                  {suggestedLocations.map((item, idx) => (
                    <SingleListItem
                      key={idx}
                      listItemText={item.description}
                      onClick={() => {
                        setLatLngFromAddress(item);
                        internalLocationSearch
                          ? setInternalSelectedLocation(item)
                          : dispatch(setSelectedLocation(item));
                        hideSuggestedLocation();
                        setShowLocationDetail(true);
                        setSearchedLocation("");
                      }}
                      contentStyle={{
                        ...styles.locationDescription,
                        ...customLocationDescription,
                      }}
                    />
                  ))}
                </Box>
              </MenuList>
            )}
          </Box>
        </Box>
      </ClickAwayListener>
      {!preventModal && showLocationPrompt && (
        <CustomModal
          omitCloseButton={!internalLocationSearch}
          open={showLocationPrompt}
          closeModalHandler={hideLocationPermissionPrompt}
          icon={LocationPrompt}
          iconAltText="Location Permission"
          heading={
            internalLocationSearch
              ? t("LOCATION_MODAL_INSTRUCTION_ONE")
              : t("LOCATION_MODAL_INSTRUCTION_TWO")
          }
          descriptionLine1={
            internalLocationSearch
              ? t("FILL_FIELDS_BASED_ON_LOCATION")
              : t("LOCATION_REQUIRED_TEXT")
          }
          descriptionLine2={
            internalLocationSearch
              ? t("ALLOW_LOCATION_OR_ENTER_ADDRESS")
              : ""
          }
          hideButtons
          selectedLocation={selectedLocation}
          showLocationSearchComponent={internalLocationSearch ? false : true}
        />
      )}
    </Box>
  );
};

LocationSearch.propTypes = {
  fillLocationDetails: PropTypes.bool,
  setLocationDetails: PropTypes.func,
  inputBaseClass: PropTypes.object,
  searchTrayContainerClass: PropTypes.object,
  customLocationDescription: PropTypes.object,
  searchTrayCustomStyles: PropTypes.object,
  allowManualAddress: PropTypes.bool,
  setValue: PropTypes.func,
  startIconStyles: PropTypes.object,
  internalLocationSearch: PropTypes.bool,
  isRequire: PropTypes.bool,
  defaultValue: PropTypes.any,
  preventModal: PropTypes.bool,
  getShowSuggestedLocationsState: PropTypes.func,
  showEndIcon: PropTypes.bool,
  locationSearchBoxContainer: PropTypes.object,
};

export default LocationSearch;
