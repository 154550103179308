import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import loginReducers from "./Login";
import signupReducers from "./SignUp";
import forgotPasswordReducers from "./ForgotPassword";
import resetPasswordReducers from "./ResetPassword";
import userProfileReducers from "./UserProfile";
import bookAnAppointmentReducers from "./BookAnAppointment";
import globalSearchReducers from "./GlobalSearch";
import toastDetailsReducer from "./ToastDetails/toastDetailsReducers";
import mapReducers from "./Map";
import familyMembersReducers from "./FamilyMembers/familyMembersReducers";
import authoriseNetPaymentReducers from "./AuthoriseNetPayment/authoriseNetPaymentReducers";
import patientReviewReducers from "./PatientReview/patientReviewReducers";
import languageReducer from "./LanguageReducer";

const reducers = {
  form: formReducer,
  signUp: signupReducers,
  login: loginReducers,
  forgotPassword: forgotPasswordReducers,
  resetPassword: resetPasswordReducers,
  userProfile: userProfileReducers,
  bookAnAppointment: bookAnAppointmentReducers,
  globalSearch: globalSearchReducers,
  toastDetails: toastDetailsReducer,
  map: mapReducers,
  familyMembers: familyMembersReducers,
  authoriseNet: authoriseNetPaymentReducers,
  patientReview: patientReviewReducers,
  languageChange: languageReducer,
};

const reducer = combineReducers(reducers);

const reducerFunc = (state, action) => reducer(state, action);
export default reducerFunc;
