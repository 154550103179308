import usaFlag from "../../img/flagImages/usa.svg";
import spainFlag from "../../img/flagImages/spain.svg";

export const languageKey = {
  en: "en",
  sp: "sp",
};
export const languageArray = [
  {
    key: languageKey.en,
    label: "English",
    flagImage: usaFlag,
    locale: "en",
  },
  {
    key: languageKey.sp,
    label: "Spanish",
    flagImage: spainFlag,
    locale: "es",
  },
];

