import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import "react-toastify/dist/ReactToastify.css";

import { NETWORK_ERROR_MESSAGE } from "../../consts/errorMessages";
import { styles } from "./NetworkCheckLayerStyles";
import "./NetworkCheckLayer.css";
import { useTranslation } from "react-i18next";

const NetworkCheckLayer = () => {
  const toastId = React.useRef(null);
  const {t}=useTranslation()

  const [networkStatus, setNetworkStatus] = useState(true);

  useEffect(() => {
    function changeNetworkStatus() {
      setNetworkStatus(navigator.onLine);
    }
    window.addEventListener("online", changeNetworkStatus);
    window.addEventListener("offline", changeNetworkStatus);
    return () => {
      window.removeEventListener("online", changeNetworkStatus);
      window.removeEventListener("offline", changeNetworkStatus);
    };
  }, []);

  if (!toastId.current && !networkStatus) {
    toastId.current = toast.error(NETWORK_ERROR_MESSAGE, {
      position: "bottom-left",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  if (networkStatus && toastId.current) {
    toast.dismiss(toastId.current);
    toastId.current = null;
  }

  return (
    <div>
      <ToastContainer
        closeButton={false}
        icon={({ type }) => {
          if (type === "error") return <ErrorIcon />;
          if (type === "success")
            return <CheckCircleIcon sx={styles.successIcon} />;
          if (type === "info") return <InfoIcon sx={styles.infoIcon} />;
          return <ReportProblemIcon sx={styles.warningIcon} />;
        }}
      />
    </div>
  );
};

export default NetworkCheckLayer;
