import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  loginResponse: {},
  isLoginApiSuccess: false,
  isSigningInUser: false,
  isLoginApiFailed: false,
  loginErrorMessage: "",
  invalidCredentialsError: false,
  isUserSessionTimeout: false,
  loginRedirectRoute: "",
  redirectRouteFromDefaultScreen: "",
  isLoggingOutUser: false,
  isNewUserIsRegistered: false,
  isAccountRestored: false,
};

const setUserSessionTimeOut = (state, action) => {
  return updateObject(state, {
    isUserSessionTimeout: action.payload,
  });
};

const setLoginState = (state, action) => {
  return updateObject(state, {
    loginResponse: action.payload,
  });
};

const setIsLoginApiSuccessful = (state, action) => {
  return updateObject(state, {
    isLoginApiSuccess: action.payload,
  });
};

const setIsLoginApiFailed = (state, action) => {
  return updateObject(state, {
    isLoginApiFailed: action.payload,
  });
};

const setLoginErrorMessage = (state, action) => {
  return updateObject(state, {
    loginErrorMessage: action.payload,
  });
};

const setInvalidCredentialsError = (state, action) => {
  return updateObject(state, {
    invalidCredentialsError: action.payload,
  });
};

const setLoginRedirectRoute = (state, action) => {
  return updateObject(state, {
    loginRedirectRoute: action.payload,
  });
};

const redirectUserFromDefaultPage = (state, action) => {
  return updateObject(state, {
    redirectRouteFromDefaultScreen: action.payload,
  });
};

const setNewUserIsRegistered = (state, action) => {
  return updateObject(state, {
    isNewUserIsRegistered: action.payload,
  });
};

const setAccountRestored = (state, action) => {
  return updateObject(state, {
    isAccountRestored: action.payload,
  });
};

const resetRedirectUserFromDefaultPage = (state, action) => {
  return updateObject(state, {
    redirectRouteFromDefaultScreen: "",
  });
};

const setIsSigningInUser = (state, action) => {
  return updateObject(state, {
    isSigningInUser: action.payload,
  });
};

const setIsLoggingOutUser = (state, action) => {
  return updateObject(state, {
    isLoggingOutUser: action.payload,
  });
};

const loginReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.IS_SIGNING_IN_USER:
      return setIsSigningInUser(state, action);
    case types.LOGIN_USER:
      return setLoginState(state, action);
    case types.IS_USER_SUCCESSFULLY_LOGGED_IN:
      return setIsLoginApiSuccessful(state, action);
    case types.IS_LOGIN_API_FAILED:
      return setIsLoginApiFailed(state, action);
    case types.LOGIN_ERROR_MESSAGE:
      return setLoginErrorMessage(state, action);
    case types.INVALID_CREDENTIALS:
      return setInvalidCredentialsError(state, action);
    case types.SESSION_TIME_OUT:
      return setUserSessionTimeOut(state, action);
    case types.LOGIN_REDIRECT_ROUTE:
      return setLoginRedirectRoute(state, action);
    case types.NEW_USER_IS_REGISTERED:
      return setNewUserIsRegistered(state, action);
    case types.ACCOUNT_IS_RESTORED:
      return setAccountRestored(state, action);
    case types.REDIRECT_USER_FROM_DEFAULT_PAGE:
      return redirectUserFromDefaultPage(state, action);
    case types.RESET_REDIRECT_USER_FROM_DEFAULT_PAGE:
      return resetRedirectUserFromDefaultPage(state, action);
    case types.LOGGING_OUT_USER:
      return setIsLoggingOutUser(state, action);
    default:
      return state;
  }
};

export default loginReducers;
