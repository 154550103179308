import React from "react";
import PropTypes from "prop-types";
import { Box, Grid, MenuItem, Typography } from "@mui/material";

import { styles } from "./SingleListItemStyles";

const SingleListItem = (props) => {
  const {
    listItemText,
    onClick,
    contentStyle,
    listItemCustomStyle,
    activeTextCondition,
    activeTextStyles,
  } = props;

  return (
    <MenuItem
      sx={{ ...styles.listItem, ...listItemCustomStyle }}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box sx={styles.listItemContainer}>
            <Typography
              sx={{
                ...(contentStyle ? contentStyle : styles.lisItemText),
                ...(activeTextCondition ? activeTextStyles : {}),
              }}
            >
              {listItemText}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </MenuItem>
  );
};

SingleListItem.propTypes = {
  listItemText: PropTypes.string,
  onClick: PropTypes.func,
  contentStyle: PropTypes.object,
  listItemCustomStyle: PropTypes.object,
  activeTextCondition: PropTypes.bool,
  activeTextStyles: PropTypes.object,
};

export default SingleListItem;
