export const SUCCESS_STATUS = 200;
export const DATA_SAVED_SUCCESSFULLY = 201;
export const UNAUTHORIZED_USER = 401;
export const ACCESS_DENIED = 403;
export const NOT_FOUND = 404;
export const PRECONDITION_FAILED = 412;

// --------------- Authorise net status code --------------------------

export const AUTHORISE_NET_RESULT_NOT_FOUND = "E00040";
export const AUTHORISE_NET_SUCCESS_STATUS = "Ok";
