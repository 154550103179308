export const ADD_FAMILY_MEMBER_BUTTON_CLICKED =
  "ADD_FAMILY_MEMBER_BUTTON_CLICKED";
export const EDIT_FAMILY_MEMBER_BUTTON_CLICKED =
  "EDIT_FAMILY_MEMBER_BUTTON_CLICKED";
export const MEMBER_SUCCESSFULLY_ADDED = "MEMBER_SUCCESSFULLY_ADDED";
export const GET_FAMILY_MEMBER_DETAILS = "GET_FAMILY_MEMBER_DETAILS";
export const IS_LOADING_FAMILY_MEMBER_DETAILS =
  "IS_LOADING_FAMILY_MEMBER_DETAILS";
export const ERROR_GETTING_FAMILY_MEMBER_DETAILS =
  "ERROR_GETTING_FAMILY_MEMBER_DETAILS";
