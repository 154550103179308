export const INITIATE_USER_SIGNUP = " INITIATE_USER_SIGNUP";
export const IS_SIGNING_UP_USER = " IS_SIGNING_UP_USER";
export const HAS_USER_FILLED_FORM_DETAILS = " HAS_USER_FILLED_FORM_DETAILS";
export const PATIENT_ALREADY_EXISTS = " PATIENT_ALREADY_EXISTS";
export const PATIENT_ALREADY_EXISTS_MESSAGE = " PATIENT_ALREADY_EXISTS_MESSAGE";
export const ERROR_SIGNING_UP_USER = " ERROR_SIGNING_UP_USER";
export const SHOW_SUCCESS_SIGNUP_TOAST = " SHOW_SUCCESS_SIGNUP_TOAST";
export const IS_USER_SUCCESSFULLY_REGISTERED =
  " IS_USER_SUCCESSFULLY_REGISTERED";
export const IS_LOADING_COUNTRY_DATA = " IS_LOADING_COUNTRY_DATA";
export const GET_COUNTRIES = " GET_COUNTRIES";
export const IS_ERROR_GETTING_COUNTRY_DATA = " IS_ERROR_GETTING_COUNTRY_DATA";
export const GET_UPLOADED_IMAGE_URL = " GET_UPLOADED_IMAGE_URL";
export const EMPTY_UPLOADED_IMAGE_URL = " EMPTY_UPLOADED_IMAGE_URL";
export const ERROR_UPLOADING_IMAGE = " ERROR_UPLOADING_IMAGE";
export const RESET_SIGNUP_FORM = " RESET_SIGNUP_FORM";
