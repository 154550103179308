import { CHERRY_RED, WHITE } from "../../consts/colors";

export const styles = {
  toastContainer: {
    position: "fixed",
    width: "100%",
    zIndex: 2,
    boxShadow: "-11px 8px 31px -2px rgba(0, 0, 0, 0.105223)",
  },
  toastContent: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: CHERRY_RED,
    color: WHITE,
    fontWeight: "bold",
  },
};
