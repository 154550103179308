import {getItem,setItem} from './localStorageService.js'
import { destroyToken } from "../utils/apiService/accountApiService";
import { SPLASH } from "./Routes";

export default async function removeToken(isAccountDeleted) {
  if (!isAccountDeleted) {
    await destroyToken();
  }
  window.location.href = SPLASH;
  const languageValue = getItem('language');
  localStorage.clear();
  if (languageValue) {
    setItem('language', languageValue);
  }
}
