import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  familyMembersDetails: null,
  isLoadingFamilyMembers: false,
  errorGettingFamilyMembersDetails: false,
  addFamilyMembers: false,
  isEditMemberDetails: false,
  isMemberSuccessFullyAdded: false,
};

const addFamilyMembersClicked = (state, action) => {
  return updateObject(state, {
    addFamilyMembers: action.payload,
  });
};

const editFamilyMembersClicked = (state, action) => {
  return updateObject(state, {
    isEditMemberDetails: action.payload,
  });
};

const memberAddedSuccessfully = (state, action) => {
  return updateObject(state, {
    isMemberSuccessFullyAdded: action.payload,
  });
};

const getFamilyMemberDetails = (state, action) => {
  return updateObject(state, {
    familyMembersDetails: action.payload,
  });
};

const loadingMemberDetails = (state, action) => {
  return updateObject(state, {
    isLoadingFamilyMembers: action.payload,
  });
};

const errorGettingMemberDetails = (state, action) => {
  return updateObject(state, {
    errorGettingFamilyMembersDetails: action.payload,
  });
};

const familyMembersReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.ADD_FAMILY_MEMBER_BUTTON_CLICKED:
      return addFamilyMembersClicked(state, action);
    case types.EDIT_FAMILY_MEMBER_BUTTON_CLICKED:
      return editFamilyMembersClicked(state, action);
    case types.MEMBER_SUCCESSFULLY_ADDED:
      return memberAddedSuccessfully(state, action);
    case types.GET_FAMILY_MEMBER_DETAILS:
      return getFamilyMemberDetails(state, action);
    case types.IS_LOADING_FAMILY_MEMBER_DETAILS:
      return loadingMemberDetails(state, action);
    case types.ERROR_GETTING_FAMILY_MEMBER_DETAILS:
      return errorGettingMemberDetails(state, action);
    default:
      return state;
  }
};
export default familyMembersReducers;
