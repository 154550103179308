import React from "react";
import { Redirect } from "react-router-dom";

import getToken from "../../Auth/getToken";
import { DASHBOARD, SPLASH } from "../../Auth/Routes";

const DefaultRoute = () => {
  const tokenCheck = getToken();
  if (tokenCheck) {
    return <Redirect to={DASHBOARD} />;
  }
  return <Redirect to={SPLASH} />;
};

export default DefaultRoute;
