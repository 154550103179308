import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  selectedPlace: null,
  directions: null,
};

const setSelectedPlace = (state, action) => {
  return updateObject(state, {
    selectedPlace: action.payload,
  });
};

const resetSelectedPlace = (state, action) => {
  return updateObject(state, {
    selectedPlace: null,
  });
};

const setDirections = (state, action) => {
  return updateObject(state, {
    directions: action.payload,
  });
};

const mapReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_SELECTED_PLACE:
      return setSelectedPlace(state, action);
    case types.RESET_SELECTED_PLACE:
      return resetSelectedPlace(state, action);
    case types.SET_DIRECTIONS:
      return setDirections(state, action);
    default:
      return state;
  }
};
export default mapReducers;
