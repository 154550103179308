import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import getToken from "../../Auth/getToken";
import { LOGIN } from "../../Auth/Routes";

const PrivateRoute = (props) => {
  const isUserLoggedIn = getToken();
  if (!isUserLoggedIn) {
    return <Redirect to={LOGIN} />;
  }
  return props.component;
};

PrivateRoute.propTypes = {
  component: PropTypes.node,
};

export default PrivateRoute;
