import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { setLoginRedirectRoute } from "../../Store/Login/loginActions";
import { getBaseParam } from "../../utils/routeHelpers";
import { LOGGED_IN_ROUTES, LOGIN, ROOT } from "../../Auth/Routes";

const RedirectRoute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  dispatch(
    setLoginRedirectRoute(
      `${location.pathname}${location.search.length ? location.search : ""}`
    )
  );

  return (
    <Redirect
      to={
        LOGGED_IN_ROUTES.includes(getBaseParam(window.location.pathname)) ||
        window.location.pathname.includes("submit-feedback")
          ? LOGIN
          : ROOT
      }
    />
  );
};

export default RedirectRoute;
