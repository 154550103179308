import React from "react";
import { Box } from "@mui/system";
import { Divider, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

import { styles } from "./HelpMenu.styles";

const HelpMenu = ({ open, anchorEl, handleClose, items, customStyles }) => {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: styles.menuPaperStyles,
      }}
    >
      {items.map(({ handleClose, name }, index) => (
        <MenuItem
          sx={{ ...styles.menuItems, ...customStyles }}
          onClick={handleClose}
          key={index}
        >
          <Box sx={styles.menuText}>{name}</Box>
          {index != items.length - 1 && <Divider sx={styles.dividerStyle} />}
        </MenuItem>
      ))}
    </Menu>
  );
};

HelpMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.element, PropTypes.object])
    .isRequired,
  handleClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  customStyles: PropTypes.object,
};

export default HelpMenu;
