import * as types from "./types";
import { SUCCESS_STATUS } from "../../Auth/statusCodes";
import axiosInstance from "../../Axios/axiosInstance";

export const addFamilyMembersClicked = (payload) => {
  return {
    type: types.ADD_FAMILY_MEMBER_BUTTON_CLICKED,
    payload,
  };
};

export const editFamilyMembersClicked = (payload) => {
  return {
    type: types.EDIT_FAMILY_MEMBER_BUTTON_CLICKED,
    payload,
  };
};

export const memberAddedSuccessfully = (payload) => {
  return {
    type: types.MEMBER_SUCCESSFULLY_ADDED,
    payload,
  };
};

export const isLoadingFamilyMemberDetails = (payload) => {
  return {
    type: types.IS_LOADING_FAMILY_MEMBER_DETAILS,
    payload,
  };
};

export const errorGettingMemberData = (payload) => {
  return {
    type: types.ERROR_GETTING_FAMILY_MEMBER_DETAILS,
    payload,
  };
};

export const getFamilyMembersDetails = () => {
  return (dispatch) => {
    dispatch(isLoadingFamilyMemberDetails(true));
    axiosInstance
      .get(`/v4/family`)
      .then((res) => {
        dispatch(isLoadingFamilyMemberDetails(false));
        dispatch(errorGettingMemberData(false));
        if (res.status === SUCCESS_STATUS) {
          dispatch({
            type: types.GET_FAMILY_MEMBER_DETAILS,
            payload: res.data,
          });
        }
      })
      .catch((err) => {
        dispatch(isLoadingFamilyMemberDetails(false));
        dispatch(errorGettingMemberData(true));
      });
  };
};
