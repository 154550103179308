import { SKY_BLUE, CORN_FLOWER_BLUE } from "../../consts/colors";

export const styles = {
  container: {
    textAlign: "center",
    borderRadius: "50%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    background: SKY_BLUE,
  },
  initialsTextStyles: {
    textTransform: "capitalize",
    color: CORN_FLOWER_BLUE,
    fontWeight: "bold",
  },
};
