export const GET_SPECIALITIES = "GET_SPECIALITIES";
export const GET_PROVIDERS = "GET_PROVIDERS";
export const IS_LOADING_PROVIDER_RESULTS = "IS_LOADING_PROVIDER_RESULTS";
export const ERROR_GETTING_PROVIDERS = "ERROR_GETTING_PROVIDERS";
export const GET_PROVIDERS_TOTAL_COUNT = "GET_PROVIDERS_TOTAL_COUNT";
export const REDIRECT_TO_PROVIDER_PAGE_WITH_SELECTED_SLOT =
  "REDIRECT_TO_PROVIDER_PAGE_WITH_SELECTED_SLOT";
export const RESET_PROVIDER_RESULTS = "RESET_PROVIDER_RESULTS";

//---------- CLINIC ACTIONS ---------
export const GET_CLINICS = "GET_CLINICS";
export const IS_LOADING_CLINIC_RESULTS = "IS_LOADING_CLINIC_RESULTS";
export const ERROR_GETTING_CLINICS = "ERROR_GETTING_CLINICS";
export const GET_CLINICS_TOTAL_COUNT = "GET_CLINICS_TOTAL_COUNT";
export const RESET_CLINICS_RESULTS = "RESET_CLINICS_RESULTS";

//------------- BOOK Appointment Details  ---------------
export const SET_BOOK_APPOINTMENT_DETAILS = "SET_BOOK_APPOINTMENT_DETAILS";
export const RESET_BOOK_APPOINTMENTS_DETAILS =
  "RESET_BOOK_APPOINTMENTS_DETAILS";
