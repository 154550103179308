import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

import CustomLoader from "../CustomLoader/CustomLoader";
import Toast from "../../components/Toast/Toast";
import { getUserData } from "../../Store/UserProfile/userProfileActions";
import { resetRedirectUserFromDefaultPage } from "../../Store/Login/loginActions";
import { resetToastDetails } from "../../Store/ToastDetails/toastDetailsActions";
import { getFamilyMembersDetails } from "../../Store/FamilyMembers/familyMembersActions";
import { SHOW_TOAST_MESSAGE_DURATION } from "../../consts/toastShowDuration";
import { styles } from "./LayoutStyles";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoading = useSelector((state) => {
    return state.userProfile.showUserProfileLoader;
  });
  const { name } = useSelector((state) => {
    return state.userProfile.currentUserData;
  });
  const { toastDetails } = useSelector((state) => {
    return state.toastDetails;
  });
  const { isErrorLoadingUserDetails } = useSelector(
    (state) => state.userProfile
  );
  const { redirectRouteFromDefaultScreen } = useSelector(
    (state) => state.login
  );
  useEffect(() => {
    dispatch(getUserData());
    dispatch(getFamilyMembersDetails());
    if (redirectRouteFromDefaultScreen) {
      history.push(redirectRouteFromDefaultScreen);
      dispatch(resetRedirectUserFromDefaultPage());
    }
  }, []);

  const closeErrorToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetToastDetails());
  };

  return (
    <React.Fragment>
      {!isErrorLoadingUserDetails ? (
        <React.Fragment>
          {!!toastDetails.message && (
            <Box sx={styles.toastContainer}>
              <Toast
                open={!!toastDetails.message}
                closeHandler={closeErrorToast}
                customToastStyle={styles.toastContent}
                alertMessage={toastDetails.message}
                severity="error"
                autoHideDuration={SHOW_TOAST_MESSAGE_DURATION}
              />
            </Box>
          )}
          {isLoading ? <CustomLoader /> : null}
          {name && !isLoading ? children : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
