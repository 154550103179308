export const SOMETHING_WENT_WRONG = "errorMessages.somethingWentWrong";
export const INVALID_CREDENTIALS = "errorMessages.invalidCredentials";
export const VALID_EMAIL = "errorMessages.validEmail";
export const INVALID_EMAIL = "errorMessages.invalidEmail";
export const PASSWORDS_DO_NOT_MATCH = "errorMessages.passwordsDoNotMatch";
export const INVALID_ENTERED_PASSWORD = "errorMessages.invalidEnteredPassword";
export const INVALID_CURRENT_PASSWORD = "errorMessages.invalidCurrentPassword";
export const INVALID_DOB = "errorMessages.invalidDob";
export const INVALID_AGE = "errorMessages.invalidAge";
export const INVALID_MOBILE_NUMBER = "errorMessages.invalidMobileNumber";
export const EMAIL_NOT_VALID = "errorMessages.emailNotValid";
export const ZIP_NOT_VALID = "errorMessages.zipNotValid";
export const PATIENT_ALREADY_EXISTS = "errorMessages.patientAlreadyExists";
export const SIGNING_UP_USER_FAILED = "errorMessages.signingUpUserFailed";
export const IS_SUBMIT_SUCCESSFULL = "errorMessages.isSubmitSuccessfull";
export const CHOOSE_SLOT_ERROR = "errorMessages.chooseSlotError";
export const APPOINTMENT_ALREADY_BOOKED_FOR_THIS_SLOT ="errorMessages.appointmentAlreadyBookedForThisSlot";
export const CHOOSE_PROVIDER_ERROR = "errorMessages.chooseProviderError";
export const ADD_SUBJECT_ERROR = "errorMessages.addSubjectError";
export const ADD_DESCRIPTION_ERROR = "errorMessages.addDescriptionError";
export const NOT_AVAILABLE_ERROR_MESSAGE = "errorMessages.notAvailableErrorMessage";
export const USER_SESSION_TIMEOUT = "errorMessages.userSessionTimeout";
export const SELECT_PATIENT_ERROR = "errorMessages.selectPatientError";
export const FILL_PURPOSE_ERROR = "errorMessages.fillPurposeError";
export const COULD_NOT_GET_PATIENT_MENU_OPTIONS = "errorMessages.couldNotGetPatientMenuOptions";
export const APPOINTMENT_BOOKING_FAILED = "errorMessages.appointmentBookingFailed";
export const ERROR_GETTING_APPOINTMENT_DETAILS =
  "errorMessages.errorGettingAppointmentDetails";
export const ERROR_GETTING_ALL_APPOINTMENTS =
  "errorMessages.errorGettingAllAppointments";
export const CANCEL_APPOINTMENT_ERROR_MESSAGE =
  "errorMessages.cancelAppointmentErrorMessage";
export const RESCHEDULE_APPOINTMENT_ERROR_MESSAGE =
  "errorMessages.rescheduleAppointmentErrorMessage";
export const ERROR_GETTING_USER_FEEDBACK =
  "errorMessages.errorGettingUserFeedback";
export const ERROR_SAVING_USER_FEEDBACK = "errorMessages.errorSavingUserFeedback";

export const insuranceCardUploadErrorMessages = {
  frontImageNotUploaded: "errorMessages.frontImageNotUploaded",
  backImageNotUploaded: "errorMessages.backImageNotUploaded",
  errorUploadingImage: "errorMessages.errorUploadingImage",
  frontImageUploadingQueued:
    "errorMessages.frontImageUploadingQueued",
  backImageUploadingQueued:
    "errorMessages.backImageUploadingQueued",
};

export const fileUploadErrorMessages = {
  fileTooLarge: "errorMesssages.fileTooLarge",
  invalidFormat: "errorMessages.invalidFormat",
  corruptImageError:
    "errorMessages.corruptImageError",
  apiCallCanceled: "errorMessages.apiCallCanceled",
};

export const userProfileErrorMesages = {
  editDetailsApiFailed: "errorMessages.editDetailsApiFailed",
};

export const familyMemberErrorMessages = {
  addMemberApiFailed: "errorMessages.addMemberApiFailed",
  getMemeberApiFailed: "errorMessages.getMemeberApiFailed",
  deleteMemberApiFailed: "errorMessages.deleteMemberApiFailed",
};
export const ERROR_ADDING_CREDIT_CARD =
  "errorMessages.errorAddingCreditCard";
export const ERROR_SETTING_CARD_AS_DEFAULT =
  "errorMessages.errorSettingCardAsDefault";
export const ERROR_DELETING_CREDIT_CARD = "errorMessages.errorDeletingCreditCard";
export const COMMON_ERROR_MESSAGE = "errorMessages.commonErrorMessage";
export const PROVIDER_LIST_API_FAILED_MESSAGE_ONE =
  "errorMessages.providerListApiFailedMessageOne";
export const API_FAILED_MESSAGE_TWO = "errorMessages.apiFailedMessageTwo";
export const NO_APPOINTMENT_LINE_ONE =
  "errorMessages.noAppointmentLineOne";
export const NO_APPOINTMENT_LINE_TWO = "errorMessages.noAppointmentLineTwo";
export const ERROR_GETTING_SLOTS = "errorMessages.errorGettingSlots";
export const MISSING_CLINIC_ANET_DETAILS_MESSAGE_ON_UI =
  "errorMessages.missingClinicAnetDetailsMessageOnUi";
export const REASON_TO_VISIT_ERROR = "errorMessages.reasonToVisitError";
export const EXISITNG_PATIENT_QUESTION_NOT_ANSWERED_ERROR =
  "errorMessages.exisitngPatientQuestionNotAnsweredError";
export const SELECT_NOTES_ERROR =
  "errorMessages.selectNotesError";
export const ERROR_UPLOADING_FRONT_IMAGE =
  "errorMessages.errorUploadingFrontImage";
export const ERROR_UPLOADING_BACK_IMAGE =
  "errorMessages.errorUploadingBackImage";
export const myReviewsErrorMessages = {
  getMyReviewsApiFailed: "errorMessages.getMyReviewsApiFailed",
  deleteMyReviewApiFailed: "errorMessages.deleteMyReviewApiFailed",
  editMyReviewApiFailed: "errorMessages.editMyReviewApiFailed",
};
export const ACCEPT_TERMS_OF_USE = "Please accept Terms of use to proceed";
export const loginErrorMessages = {
  WRONG_CREDENTIALS: "login.wrongCredentials",
  NOT_AUTHORIZED: "login.notAuthorized",
};
export const ERROR_SUBMITTING_REVIEW_AFTER_SUCCESSFUL_SIGNUP =
  "errorMessages.errorSubmittingReviewAfterSuccessfulSignup";
export const ERROR_INITIATING_ACCOUNT_DELETION =
  "errorMessages.errorInitiatingAccountDeletion";
export const ERROR_DELETING_USER_ACCOUNT =
  "errorMessages.errorDeletingUserAccount";
export const ERROR_GETTING_DIRECTIONS =
  "errorMessages.errorGettingDirections";
export const GENERIC_GET_API_FAILED_ERROR_MESSAGE =
  "Something went wrong ! Please try again later";
export const NETWORK_ERROR = "Network Error";
export const NETWORK_ERROR_MESSAGE =
  "You are currently not connected to the internet. Please check your internet connection and try again.";
export const GETTING_INSURANCE_ERROR_MESSAGE =
  "errorMessages.gettingInsuranceErrorMessage";
export const CANCEL_API = "Cancel API request";
