import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import { styles } from "./InitialsImageStyles";

const InitialsImage = (props) => {
  const { initials, initialsImageCustomStyle, initialsTextCustomStyle } = props;

  return (
    <Box sx={{ ...styles.container, ...initialsImageCustomStyle }}>
      <Typography
        sx={{ ...styles.initialsTextStyles, ...initialsTextCustomStyle }}
      >
        {initials}
      </Typography>
    </Box>
  );
};

InitialsImage.propTypes = {
  initials: PropTypes.string,
  initialsImageCustomStyle: PropTypes.object,
  initialsTextCustomStyle: PropTypes.object,
};

export default InitialsImage;
