import * as types from "./types";
import axiosInstance from "../../Axios/axiosInstance";
import removeToken from "../../Auth/removeToken";
import { SUCCESS_STATUS, UNAUTHORIZED_USER } from "../../Auth/statusCodes.js";
import { urlService } from "../../utils/urlService";

export const showUserProfileLoader = (payload) => {
  return {
    type: types.SHOW_USER_PROFILE_LOADER,
    payload,
  };
};

export const errorFetchingUserDetails = (payload) => {
  return {
    type: types.IS_ERROR_FETCHING_USER_DETAILS,
    payload,
  };
};

export const setTab = (payload) => {
  return {
    type: types.SET_TAB,
    payload,
  };
};

export const resetTab = () => {
  urlService.removeParam("accountSection");
  return {
    type: types.RESET_TAB,
  };
};

export const editDetailsHandler = (payload) => {
  return {
    type: types.EDIT_DETAILS_HANDLER,
    payload,
  };
};

export const getUserData = () => {
  return (dispatch) => {
    dispatch(showUserProfileLoader(true));
    axiosInstance
      .get("/v4/patientAppUser")
      .then((res) => {
        if (res.status === SUCCESS_STATUS) {
          dispatch({
            type: types.CURRENT_USER_DATA,
            payload: res.data[0],
          });
          dispatch(showUserProfileLoader(false));
          dispatch(errorFetchingUserDetails(false));
        }
      })
      .catch((err) => {
        if (err?.response?.status !== UNAUTHORIZED_USER) {
          removeToken();
        }
        dispatch(showUserProfileLoader(false));
        dispatch(errorFetchingUserDetails(true));
      });
  };
};
