import { createTheme } from "@mui/material";
import { fontFamily } from "./commonStyles";

const theme = createTheme({
  typography: {
    fontFamily: [fontFamily],
  },
});

export default theme;
