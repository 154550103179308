import getToken from "../../Auth/getToken";
import axiosInstance from "../../Axios/axiosInstance";
import NovaAPI from "../../Axios/NovaAPI";
import { setLoggingOutUser } from "../../Store/Login/loginActions";
import { store } from "../../Store/store";

export const changePassword = async (newPasword, currentPassword) => {
  let response = { data: null, error: "" };
  const apiData = { new: newPasword || "", current: currentPassword || "" };
  try {
    response.data = await axiosInstance.put(`/v4/password`, { ...apiData });
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const updateUserDetails = async (userDetails) => {
  let response = { data: null, error: "" };
  try {
    response.data = await axiosInstance.put(`/v4/patientAppUser`, {
      ...userDetails,
    });
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const supportEmail = async (subject, message) => {
  let response = { data: null, error: "" };
  const supportEmailData = { subject: subject || "", message: message || "" };
  try {
    response.data = await axiosInstance.put(`/v4/supportEmail`, {
      ...supportEmailData,
    });
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const destroyToken = async () => {
  let response = { date: null, error: "" };
  try {
    store.dispatch(setLoggingOutUser(true));
    response.data = await NovaAPI.delete(`/v4/session`, {
      headers: {
        "x-auth-token": getToken(),
      },
    });
    store.dispatch(setLoggingOutUser(false));
  } catch (err) {
    response.error = err.response;
    store.dispatch(setLoggingOutUser(false));
  }
};

export const deleteUserAccountApi = async ({ consent }) => {
  let response = { date: null, error: "" };
  try {
    response.data = await axiosInstance.put(
      `/v4/deleteFadAccount?consent=${consent ? consent : false}`,
      {}
    );
  } catch (err) {
    response.error = err.response;
  }
  return response;
};

export const updateInsuranceCards = async ({
  appUserId,
  insuranceDetails,
  isFamilyMember,
}) => {
  const payload = {
    appUserId,
    insuranceDetails,
    isFamilyMember,
  };
  let response = { date: null, error: "" };
  try {
    response.data = await axiosInstance.put(
      `/v4/updateFadMemberInsurance`,
      payload
    );
  } catch (err) {
    response.error = err.response;
  }
  return response;
};
