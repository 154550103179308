import { LIGHT_GREEN, VIBRANT_GREEN } from "../../consts/colors";

export const styles = {
  toastMessage: {
    justifyContent: "center",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    width: "100%",
  },
  toastSuccessMessage: {
    width: "100%",
    backgroundColor: LIGHT_GREEN,
    color: VIBRANT_GREEN,
    justifyContent: "center",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
  },
};
