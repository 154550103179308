/* When adding new route, please add that route in validateExistingRoutes method of routeHelpers.js file */

import { getBaseParam } from "../utils/routeHelpers";

export const ROOT = "/";
export const ACCOUNT_SECTION = "/account-section";
export const ALL_APPOINTMENTS = "/all-appointments";
export const APPOINTMENT_DETAILS = "/appointment-details/:appointmentId";
export const BOOK_NEW_APPOINTMENT = "/book-new-appointment";
export const CLINIC_DETAILS = "/clinic-details/:id";
export const DASHBOARD = "/dashboard";
export const FAVORITES = "/favorites";
export const FORGOT_PASSWORD = "/forgot-password";
export const HIPPA_ROUTE = "/hippa";
export const LIST_PRACTICE = "/list-your-practice";
export const LOGIN = "/login";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const PROVIDER_DETAILS = "/provider-details/:id";
export const RESET_PASSWORD = "/reset/:token";
export const SEARCH_LISTING = "/search-listing";
export const SIGNUP = "/signup";
export const SPLASH = "/featured-search";
export const SUBMIT_FEEDBACK = "/submit-feedback";
export const TERMS_AND_CONDITION = "/terms-and-conditions";
export const USER_AGREEMENT = "/useragreement";
export const DEFAULT = "*";

export const LOGGED_IN_ROUTES = [
  getBaseParam(DASHBOARD),
  getBaseParam(BOOK_NEW_APPOINTMENT),
  getBaseParam(SEARCH_LISTING),
  getBaseParam(ALL_APPOINTMENTS),
  getBaseParam(APPOINTMENT_DETAILS),
  getBaseParam(FAVORITES),
  getBaseParam(ACCOUNT_SECTION),
];
