import { DIM_GREY, GREY95 } from "../../consts/colors";

export const styles = {
  imageDimension: {
    width: "90px",
    height: "90px",
  },
  noImageBackground: {
    backgroundColor: GREY95,
    color: DIM_GREY,
  },
  squareImageStyles: {
    borderRadius: "0.5rem",
  },
};
