import React from "react";
import { useHistory, useLocation } from "react-router";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Box, Grid, MenuList, Tooltip, Typography } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import RoomIcon from "@mui/icons-material/Room";

import RenderDropDownField from "./RenderDropDownField";
import ImageOrInitials from "../ImageOrInitials/ImageOrInitials";
import { getBaseURLWithoutQueryParams } from "../../utils/routeHelpers";
import { setTab } from "../../Store/UserProfile/userProfileActions";
import { CARE_PULSE_BROCHURE } from "../../consts/constant";
import { styles } from "./HeaderStyles";

const HeaderActionDropDown = (props) => {
  const {
    name,
    profileImage,
    email,
    country,
    city,
    state,
    closeActionDropdown,
    setCustomModalDetails,
    dropdownItems,
    openBrochure,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const setCustomModalDetailsFromDataItem = (data) => {
    closeActionDropdown();
    setCustomModalDetails({
      showModal: true,
      heading: data.confirmationHeading || "",
      confirmDeleteHandler: () => {
        data.onClickActionItem();
      },
      closeModalHandler: () => {
        setCustomModalDetails({});
      },
    });
  };

  const onClickDataItem = (event, data) => {
    data?.tab && dispatch(setTab(data?.tab));
    data.onClickActionItem &&
      (!data.askConfirmation
        ? data.onClickActionItem()
        : setCustomModalDetailsFromDataItem(data));
    data.redirectPath &&
      getBaseURLWithoutQueryParams(data.redirectPath) !== location.pathname &&
      history.push(data.redirectPath);
    !data.askConfirmation && closeActionDropdown();
    event.stopPropagation();
  };

  const newDropDownArray = dropdownItems.map((item) => {
    if (item.actionText === "CARE_PULSE_BROCHURE") {
      item.onClickActionItem = () => {
        openBrochure();
      };
    }
    return item;
  });

  return (
    <Box sx={styles.dropDownContainer}>
      <MenuList sx={styles.menuListWrapper}>
        {!!name && (
          <Grid container sx={styles.userInfoContainer}>
            <Grid item xs={4} sx={styles.leftGrid}>
              <ImageOrInitials
                image={profileImage}
                text={name}
                customStyles={styles.profileImageStyles}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography sx={styles.dropDownMainInfo}>{name}</Typography>
              <Box sx={styles.row}>
                <MailOutlineIcon sx={styles.dropdownInfoIcon} />
                <Typography sx={styles.dropdownInfoText}>{email}</Typography>
              </Box>
              <Box sx={styles.row}>
                <RoomIcon sx={styles.dropdownInfoIcon} />
                <Tooltip title={`${country}, ${city} ${state}`}>
                  <Typography
                    sx={styles.dropdownInfoText}
                  >{`${country}, ${city} ${state}`}</Typography>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        )}
        {newDropDownArray.map((data, idx) => (
          <RenderDropDownField
            key={idx}
            tabDataField={data}
            {...{ onClickDataItem, dropdownItems }}
            index={idx}
          />
        ))}
      </MenuList>
    </Box>
  );
};

HeaderActionDropDown.propTypes = {
  name: PropTypes.string,
  profileImage: PropTypes.string,
  email: PropTypes.string,
  country: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  closeActionDropdown: PropTypes.func,
  setCustomModalDetails: PropTypes.func,
  dropdownItems: PropTypes.array,
  openBrochure: PropTypes.func,
};

export default HeaderActionDropDown;
