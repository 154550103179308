import {
  ACCOUNT_SECTION,
  ALL_APPOINTMENTS,
  APPOINTMENT_DETAILS,
  BOOK_NEW_APPOINTMENT,
  CLINIC_DETAILS,
  DASHBOARD,
  FAVORITES,
  FORGOT_PASSWORD,
  HIPPA_ROUTE,
  LIST_PRACTICE,
  LOGIN,
  PRIVACY_POLICY_ROUTE,
  PROVIDER_DETAILS,
  RESET_PASSWORD,
  SEARCH_LISTING,
  SIGNUP,
  SPLASH,
  SUBMIT_FEEDBACK,
  TERMS_AND_CONDITION,
} from "../Auth/Routes";

export const getBaseParam = (route) => {
  if (!route) {
    return "";
  }
  const routeList = route.split("/");
  return routeList[1];
};

export const getBaseURLWithoutQueryParams = (route) => {
  if (!route) return "";
  if (route.includes("?")) {
    const baseRoute = route.split("?")[0];
    return baseRoute;
  }
  return route;
};

export const validateExistingRoutes = (path) => {
  switch (getBaseParam(path)) {
    case getBaseParam(ACCOUNT_SECTION):
      return ACCOUNT_SECTION;
    case getBaseParam(ALL_APPOINTMENTS):
      return ALL_APPOINTMENTS;
    case getBaseParam(APPOINTMENT_DETAILS):
      return APPOINTMENT_DETAILS;
    case getBaseParam(BOOK_NEW_APPOINTMENT):
      return BOOK_NEW_APPOINTMENT;
    case getBaseParam(CLINIC_DETAILS):
      return CLINIC_DETAILS;
    case getBaseParam(DASHBOARD):
      return DASHBOARD;
    case getBaseParam(FAVORITES):
      return FAVORITES;
    case getBaseParam(FORGOT_PASSWORD):
      return FORGOT_PASSWORD;
    case getBaseParam(HIPPA_ROUTE):
      return HIPPA_ROUTE;
    case getBaseParam(LIST_PRACTICE):
      return LIST_PRACTICE;
    case getBaseParam(LOGIN):
      return LOGIN;
    case getBaseParam(PRIVACY_POLICY_ROUTE):
      return PRIVACY_POLICY_ROUTE;
    case getBaseParam(PROVIDER_DETAILS):
      return PROVIDER_DETAILS;
    case getBaseParam(RESET_PASSWORD):
      return RESET_PASSWORD;
    case getBaseParam(SEARCH_LISTING):
      return SEARCH_LISTING;
    case getBaseParam(SIGNUP):
      return SIGNUP;
    case getBaseParam(SPLASH):
      return SPLASH;
    case getBaseParam(SUBMIT_FEEDBACK):
      return SUBMIT_FEEDBACK;
    case getBaseParam(TERMS_AND_CONDITION):
      return TERMS_AND_CONDITION;
    default:
      return "";
  }
};
