import { BERN_RED, BROWN_HEADING, ECLIPSE, GREY97 } from "../../consts/colors";

export const styles = {
  container: {
    backgroundColor: GREY97,
    padding: "1.5rem",
  },
  loaderStyles: {
    minHeight: "25vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
  },
  closeButton: {
    padding: "0",
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  closeIcon: {
    fill: BROWN_HEADING,
  },
  headingBox: {
    marginBottom: "1rem",
    maxWidth: "92%",
  },
  image: {
    width: "56px",
    height: "56px",
  },
  row: {
    display: "flex",
    gap: "1.5rem",
    alignItems: "center",
  },
  lineOne: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    wordBreak: "break-word",
  },
  marginBottom: {
    marginBottom: "4px",
  },
  descriptionOtherLines: {
    color: BROWN_HEADING,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    wordBreak: "break-word",
  },
  alert: {
    color: BERN_RED,
    fontWeight: "bold",
  },
};
