import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Box } from "@mui/material";

import { styles } from "./CustomLoaderStyles";

const CustomLoader = (props) => {
  const { customHeight } = props;

  return (
    <Box sx={{ ...styles.loaderContainerStyles, ...customHeight }}>
      <CircularProgress sx={styles.loaderStyles} />
    </Box>
  );
};

CustomLoader.propTypes = {
  customHeight: PropTypes.object,
};

export default CustomLoader;
