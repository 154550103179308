import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isLoadingCountryOptions: false,
  isSigningUpUser: false,
  countryMenuOptions: null,
  isErrorGettingCountryData: false,
  hasUserFilledFormDetails: false,
  isUserSignUpInitiated: false,
  isUserSuccessfullyRegistered: false,
  isUserAlreadyRegistered: false,
  patientAlreadyExistsMessage: "",
  isShowSuccessSignupToast: false,
  isErrorUploadingImage: false,
  errorSigningUpPatient: "",
};

const isLoadingCountryOptions = (state, action) => {
  return updateObject(state, {
    isLoadingCountryOptions: action.payload,
  });
};

const isErrorGettingCountryOptions = (state, action) => {
  return updateObject(state, {
    isErrorGettingCountryData: action.payload,
  });
};

const getCountries = (state, action) => {
  return updateObject(state, {
    countryMenuOptions: action.payload,
  });
};

const setIsUserSigningUp = (state, action) => {
  return updateObject(state, {
    isSigningUpUser: action.payload,
  });
};

const initiateUserSignUp = (state, action) => {
  return updateObject(state, {
    isUserSignUpInitiated: action.payload,
  });
};

const hasUserFilledFormDetails = (state, action) => {
  return updateObject(state, {
    hasUserFilledFormDetails: action.payload,
  });
};

const isUserSuccessfullyRegistered = (state, action) => {
  return updateObject(state, {
    isUserSuccessfullyRegistered: action.payload,
  });
};

const errorUploadingImage = (state, action) => {
  return updateObject(state, {
    isErrorUploadingImage: action.payload,
  });
};

const patientAlreadyExists = (state, action) => {
  return updateObject(state, {
    isUserAlreadyRegistered: action.payload,
  });
};

const patientAlreadyExistsMessage = (state, action) => {
  return updateObject(state, {
    patientAlreadyExistsMessage: action.payload,
  });
};

const setErrorSigningUpPatient = (state, action) => {
  return updateObject(state, {
    errorSigningUpPatient: action.payload,
  });
};

const showSuccessSignupToast = (state, action) => {
  return updateObject(state, {
    isShowSuccessSignupToast: action.payload,
  });
};

const resetSignupForm = (state, action) => {
  return updateObject(state, {
    isUserSignUpInitiated: false,
    hasUserFilledFormDetails: false,
    isUserSuccessfullyRegistered: false,
    isLoadingCountryOptions: false,
    isErrorGettingCountryData: false,
    isShowSuccessSignupToast: false,
    countryMenuOptions: null,
    uploadedImageURL: "",
    isUserAlreadyRegistered: false,
    patientAlreadyExistsMessage: "",
    isErrorUploadingImage: false,
  });
};

const signUpReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return getCountries(state, action);
    case types.IS_LOADING_COUNTRY_DATA:
      return isLoadingCountryOptions(state, action);
    case types.IS_ERROR_GETTING_COUNTRY_DATA:
      return isErrorGettingCountryOptions(state, action);
    case types.IS_SIGNING_UP_USER:
      return setIsUserSigningUp(state, action);
    case types.INITIATE_USER_SIGNUP:
      return initiateUserSignUp(state, action);
    case types.PATIENT_ALREADY_EXISTS:
      return patientAlreadyExists(state, action);
    case types.PATIENT_ALREADY_EXISTS_MESSAGE:
      return patientAlreadyExistsMessage(state, action);
    case types.HAS_USER_FILLED_FORM_DETAILS:
      return hasUserFilledFormDetails(state, action);
    case types.IS_USER_SUCCESSFULLY_REGISTERED:
      return isUserSuccessfullyRegistered(state, action);
    case types.ERROR_UPLOADING_IMAGE:
      return errorUploadingImage(state, action);
    case types.SHOW_SUCCESS_SIGNUP_TOAST:
      return showSuccessSignupToast(state, action);
    case types.ERROR_SIGNING_UP_USER:
      return setErrorSigningUpPatient(state, action);
    case types.RESET_SIGNUP_FORM:
      return resetSignupForm(state, action);
    default:
      return state;
  }
};

export default signUpReducers;
