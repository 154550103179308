import {
  Twitter,
  FacebookOutlined,
  LinkedIn,
  YouTube,
  Pinterest,
} from "@mui/icons-material";

import instagramIcon from "../img/socialIcons/InstagramIcon.svg";
import tiktokIcon from "../img/socialIcons/TiktokIcon.svg";

export const mainLinksName = {
  privacyPolicy: "Privacy Policy",
  hippa: "HIPAA",
  termsOfService: "Terms of Use",
};

export const staticInfo = {
  phoneNumber: "888.838.6682",
  address: "6310 Greenwich Dr. Suite 220, San Diego, CA 92122",
  tnc: "© 2016-2022 All rights reserved",
};

export const mobileAppLinks = {
  playStore:
    "https://play.google.com/store/apps/details?id=com.novadontics.fad",
  appStore: "https://apps.apple.com/us/app/novadontics-fad/id1660221299",
};

export const socialIconsArray = [
  {
    name: "Linked In",
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/mycarepulse",
  },
  {
    name: "Facebook",
    icon: FacebookOutlined,
    link: "https://www.facebook.com/MyCarePulse",
  },
  {
    name: "Instagram",
    svg: instagramIcon,
    link: "https://www.instagram.com/MyCarePulse",
  },
  {
    name: "Twitter",
    icon: Twitter,
    link: "https://twitter.com/MyCarePulse",
  },
  {
    name: "YouTube",
    icon: YouTube,
    link: "https://www.youtube.com/@CarePulseLLC",
  },
  {
    name: "Pinterest",
    icon: Pinterest,
    link: "https://in.pinterest.com/MyCarePulse/",
  },
  {
    name: "TikTok",
    svg: tiktokIcon,
    link: "https://www.tiktok.com/@mycarepulse",
  },
];
