import {
  ALERT_USER,
  BLACK_RUSSIAN,
  BRIGHT_GREY,
  APP_PRIMARY_THEME_COLOR,
  DIM_GREY,
  ECLIPSE,
  GREY78,
  GREY91,
  GREY95,
  GREY97,
  LIGHTER_TEXT,
  APP_THEME_THREE,
  METALLIC_SILVER,
  APP_THEME_TWO,
  APP_SECONDARY_THEME_COLOR,
  SILVER_GREY,
  APP_THEME_ONE,
  TEXT_RED,
  WHITE,
} from "../../consts/colors";

export const fontFamily = "Plus Jakarta Sans";

export const commonStyles = {
  container: {
    backgroundColor: GREY97,
    padding: "1% 3%",
    borderTopLeftRadius: "1.5rem",
    borderTopRightRadius: "1.5rem",
    flex: 1,
  },
  customLoader: {
    color: APP_SECONDARY_THEME_COLOR,
  },
  customClearIconStyles: {
    height: "18px",
    width: "18px",
  },
  errorInput: {
    border: `1px solid ${TEXT_RED}`,
  },
  validityError: {
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: "8px",
    color: TEXT_RED,
  },
  errorMessage: {
    fontSize: "16px",
    fontWeight: "600",
    color: APP_SECONDARY_THEME_COLOR,
    textTransform: "capitalize",
  },
  errorMessageSubHeading: {
    fontSize: "14px",
    fontWeight: "400",
    color: LIGHTER_TEXT,
    textTransform: "capitalize",
  },
  customSrollBar: {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      width: "7px",
      borderRadius: "12px",
      background: SILVER_GREY,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "12px",
      background: METALLIC_SILVER,
    },
  },
  hideScrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    scrollbarWidth: "none" /* Firefox */,
  },
  customInitialsText: {
    fontSize: "25px",
    lineHeight: "32px",
  },
  inputField: {
    backgroundColor: GREY97,
    border: `1px solid ${BRIGHT_GREY}`,
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: "600",
    color: `${DIM_GREY}`,
    padding: "20px 16px",
    height: "1.8rem",
    width: "100%",
    ".MuiInputBase-input": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  passwordInstructionsContainer: {
    width: "90%",
    marginBottom: "1rem",
  },
  passwordInstructionHeading: {
    color: DIM_GREY,
    marginBottom: "0.25rem",
  },
  passwordInstructionDefault: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "500",
  },
  passwordStrengthText: {
    color: DIM_GREY,
  },
  passwordInstruction: {
    color: APP_SECONDARY_THEME_COLOR,
  },
  passwordValidated: {
    color: APP_THEME_ONE,
  },
  heading: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "40px",
  },
  normalHeading: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
  },
  mobileHeading: {
    "@media (max-width : 600px)": {
      color: ECLIPSE,
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "32px",
    },
  },
  noResultSubHeading: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
    color: DIM_GREY,
    "@media (max-width: 600px)": {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  divider: {
    border: `0.25px solid ${BRIGHT_GREY}`,
    width: "100%",
  },
  sharpDivider: {
    color: GREY91,
    width: "100%",
    "@media (max-width: 600px)": {
      marginBottom: "1rem",
    },
  },
  filterPillContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    position: "relative",
    width: "100%",
    marginTop: 0,
    padding: "0% 3% 1% 3%",
    "@media (max-width: 600px)": {
      gap: "10px",
    },
  },
  filterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: WHITE,
    boxShadow: "-11px 8px 31px -2px rgba(0, 0, 0, 0.105223)",
    borderRadius: "12px",
    minWidth: "139px",
    minHeight: "32px",
    boxSizing: "border-box",
    cursor: "pointer",
    position: "relative",
    "@media (max-width : 600px)": {
      minWidth: "auto",
      padding: "5px 12px",
    },
  },
  filterPillCustomStyle: {
    "@media (max-width : 1200px)": {
      padding: "2px 6px",
    },
  },
  imageGap: {
    gap: "7px",
  },
  activeFilterIcon: {
    color: WHITE,
  },
  filterText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
    color: ECLIPSE,
  },
  activeText: {
    color: WHITE,
    fontWeight: "500",
  },
  selectedFilterOptionSvg: {
    color: APP_PRIMARY_THEME_COLOR,
    weight: "20px",
    height: "20px",
  },
  highlightBox: {
    backgroundColor: WHITE,
    boxShadow: "0px 2px 3px -1.5px rgba(0, 27, 77, 0.05)",
    borderRadius: "1rem",
    padding: "1.5rem",
    "@media (max-width: 600px)": {
      padding: "1rem",
    },
  },
  nameStyles: {
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "40px",
    color: ECLIPSE,
    "@media (max-width : 600px)": {
      fontSize: "24px",
      lineHeight: "30px",
      textAlign: "center",
    },
  },
  uploadPictureContainer: {
    position: "relative",
    display: "flex",
    width: "154px",
    marginTop: "1rem",
  },
  accountTopButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
    width: "auto",
    padding: "8px 24px",
    height: "40px",
    borderRadius: "0.5rem",
    color: GREY91,
    boxShadow: "0px 0.95px 3.8px rgba(0, 0, 0, 0.25)",
    "@media (max-width : 600px)": {
      width: "auto",
      marginRight: "0",
      marginTop: "1rem",
    },
  },
  leftGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "3rem",
    paddingLeft: "4rem",
    height: "inherit",
    "@media (max-width: 900px)": {
      justifyContent: "start",
      paddingLeft: "3rem",
    },
    "@media (max-width: 500px)": {
      padding: "1rem",
      paddingTop: "2rem",
    },
  },
  checkBoxLabel: {
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: "400",
    color: ECLIPSE,
  },
  checkBoxLabelLink: {
    color: APP_THEME_ONE,
  },
  labelStyles: {
    color: BLACK_RUSSIAN,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  disabledLabelStyles: {
    opacity: "0.5",
  },
  checkBoxStyles: {
    color: BRIGHT_GREY,
    "&.Mui-checked": {
      color: APP_PRIMARY_THEME_COLOR,
    },
  },
  simpleOrangeTextLkeButton: {
    textTransform: "capitalize",
    color: APP_PRIMARY_THEME_COLOR,
  },
  mobileModalRow: {
    "@media (max-width : 600px)": {
      flexDirection: "column",
      gap: "8px",
    },
  },
  mobileModalText: {
    "@media (max-width : 600px)": {
      textAlign: "center",
    },
  },
  mobileBoldHeading: {
    "@media (max-width : 600px)": {
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "28px",
      color: ECLIPSE,
    },
  },
  customSelectBoxStyles: {
    backgroundColor: GREY97,
    border: `1px solid ${BRIGHT_GREY}`,
    color: `${DIM_GREY}`,
  },
  deleteMobileBtnText: {
    color: DIM_GREY,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
  },
  title: {
    color: APP_PRIMARY_THEME_COLOR,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
    marginBottom: "0.25rem",
  },
  buttonBox: {
    marginTop: "2rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "@media (max-width : 600px)": {
      flexDirection: "column",
      marginTop: "1.5rem",
    },
  },
  commonButtonStyles: {
    height: "40px",
    width: "137px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  buttonTwoStyles: {
    color: DIM_GREY,
    border: `1px solid ${GREY78}`,
    backgroundColor: WHITE,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  singleLineRow: {
    display: "flex",
    gap: "1rem",
  },
  noResults: {
    color: GREY78,
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "32px",
  },
  notesForOfficeBox: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    marginBottom: "4px",
  },
  inputBaseClass: {
    width: "100%",
    height: "60px",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none",
    paddingRight: "0",
    paddingLeft: 0,
    "@media (max-width : 1200px)": {
      padding: "0px 0.5rem",
    },
    "@media (max-width : 600px)": {
      height: "52px",
      fontSize: "14px",
    },
  },
  startIconStyles: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  singleListSection: {
    marginLeft: "1.5rem",
    "@media (max-width:900px)": {
      marginLeft: "1rem",
    },
  },
  commonSearchTrayStyles: {
    position: "absolute",
    backgroundColor: GREY95,
    maxHeight: "25rem",
    overflow: "auto",
    zIndex: "5",
    borderRadius: "6px",
    boxShadow: "-2px 9px 25px 2px rgb(64 102 147 / 23%)",
  },
  noResultsFoundContainer: {
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  noResultFoundImage: {
    width: "500px",
    height: "200px",
    padding: "5%",
    paddingBottom: "3%",
    "@media (max-width : 600px)": {
      width: "300px",
      height: "150px",
    },
  },
  optionText: {
    fontSize: "15px",
    lineHeight: "20px",
    textTransform: "capitalize",
  },
  loaderOnInputStyles: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  descriptiveText: {
    color: DIM_GREY,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    "@media (max-width:600px)": {
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
};

export const slotsModalStyles = {
  "& .MuiDialog-container .MuiPaper-root": {
    minWidth: "50vw",
    minHeight: "50vh",
    borderRadius: "12px",
    ...commonStyles.customSrollBar,
  },
};

export const modalCommonStyles = {
  modal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
    },
  },
  minWidthModal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
      minWidth: "30vw",
      "@media (max-width: 900px)": {
        minWidth: "50vw",
      },
    },
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "40vh",
  },
  loader: {
    color: APP_SECONDARY_THEME_COLOR,
  },
};

export const fileUploadCommonStyles = {
  container: {
    border: `1px solid ${BRIGHT_GREY}`,
    borderRadius: "4px",
    backgroundColor: WHITE,
    padding: "1rem",
    "@media (max-width : 600px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  imageContainer: {
    display: "flex",
  },
  placeholderImage: {
    cursor: "pointer",
  },
  uploadTextAndBtnContainer: {
    "@media (max-width : 600px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  uploadTextContainer: {
    display: "flex",
    alignItems: "center",
    "@media (max-width : 600px)": {
      justifyContent: "center",
    },
  },
  uploadDocumentText: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    "@media (max-width : 600px)": {
      textAlign: "center",
      fontSize: "14px",
    },
  },
  uploadButtonContainer: {
    width: "100%",
    textAlign: "end",
    "@media (max-width : 600px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  formatInstructionContainerStyles: {
    "@media (max-width: 600px)": {
      textAlign: "center",
    },
  },
  descriptiveTextLabel: {
    color: DIM_GREY,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
  },
  descriptiveTextValue: {
    color: ECLIPSE,
  },
  uploadDisabledStyles: {
    cursor: "default",
  },
};

export const commonSelectBoxStyles = {
  selectBoxContainer: {
    width: "100%",
  },
  customSelectBoxStyles: {
    backgroundColor: GREY97,
    border: `1px solid ${BRIGHT_GREY}`,
    color: `${DIM_GREY}`,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 500,
    paddingLeft: "1rem",
  },
  customMenu: {
    maxHeight: "200px",
    overFlow: "auto",
  },
  menuOptions: {
    height: "40px",
    fontSize: "14px",
    lineHeight: "22px",
    color: DIM_GREY,
    fontWeight: 500,
    "&:hover": {
      background: GREY91,
      "&:selected": {
        background: APP_THEME_THREE,
      },
    },
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: APP_THEME_TWO,
      color: WHITE,
    },
  },
  errorStyles: {
    border: `1px solid ${ALERT_USER}`,
  },
  grayMenuStyles: {
    backgroundColor: GREY97,
  },
};

export const searchTrayStyles = {
  searchTray: {
    top: "108%",
    width: "180%",
    position: "absolute",
    ...commonStyles.commonSearchTrayStyles,
    ...commonStyles.customSrollBar,
    "@media (max-width : 1200px)": {
      width: "inherit",
    },
  },
  loaderContainer: {
    overflow: "none",
  },
  loadingStyles: {
    fontWeight: "600",
    color: APP_THEME_THREE,
    padding: "2.5%",
    "@media (min-width : 2200px)": {
      padding: "1% 2.5% 1% 2.5%",
    },
  },
};

export const errorStyles = {
  errorContainer: {
    backgroundColor: GREY97,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  errorContainerMinHeight: {
    minHeight: "100vh",
  },
  errorMessage: {
    color: APP_SECONDARY_THEME_COLOR,
    fontSize: "25px",
    lineHeight: "32px",
    fontWeight: "bold",
    textAlign: "center",
  },
  blackErrorMessage: {
    color: DIM_GREY,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
    "@media (max-width : 600px)": {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  errorTextSmall: {
    fontSize: "20px",
    lineHeight: "27px",
  },
  errorTextMessage: {
    color: ALERT_USER,
    fontSize: "14px",
    lineHight: "20px",
    fontWeight: 500,
  },
  errorButton: {
    marginTop: "1rem",
    background: APP_THEME_TWO,
    boxShadow: "0px 0.95px 3.8px rgba(0, 0, 0, 0.25)",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "18px",
    color: ECLIPSE,
    border: "none",
    width: "8rem",
    borderRadius: "12px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: APP_THEME_TWO,
    },
    "@media (max-width : 600px)": {
      width: "8rem",
    },
  },
  errorText: {
    color: APP_PRIMARY_THEME_COLOR,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 500,
    "@media (max-width: 600px)": {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
};
