import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CustomLoader from "../CustomLoader/CustomLoader";
import CustomModal from "../CustomModal/CustomModal";
import LocationPrompt from "../../img/LocationPrompt.svg";
import NetworkCheckLayer from "../../containers/NetworkCheckLayer/NetworkCheckLayer";
import { getBaseParam, validateExistingRoutes } from "../../utils/routeHelpers";
import { getCountryFromLatLng } from "../../utils/utils";
import { loadScript } from "../../utils/loadScript";
import {
  setCurrentLocationInfo,
  setCurrentLocation,
  setIsGettingCurrentLocationInfo,
} from "../../Store/GlobalSearch/globalSearchActions";
import { urlService } from "../../utils/urlService";
import {
  ACCOUNT_SECTION,
  ALL_APPOINTMENTS,
  FORGOT_PASSWORD,
  HIPPA_ROUTE,
  LIST_PRACTICE,
  LOGIN,
  PRIVACY_POLICY_ROUTE,
  RESET_PASSWORD,
  SIGNUP,
  TERMS_AND_CONDITION,
} from "../../Auth/Routes";
import classes from "./LocationWrapperStyles.module.css";

const LocationWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isGoogleScriptLoaded, setIsGoogleScriptLoaded] = useState(false);
  const {
    currentLocation,
    currentLocationInfo,
    isGettingCurrentLocationInfo,
    isLoadingCurrentLocation,
    selectedLocation,
  } = useSelector((state) => {
    return state.globalSearch;
  });

  window.initMap = () => {
    setIsGoogleScriptLoaded(true);
  };

  const shouldLoadGoogleScript =
    !!validateExistingRoutes(location.pathname) &&
    location.pathname !== TERMS_AND_CONDITION &&
    location.pathname !== PRIVACY_POLICY_ROUTE &&
    location.pathname !== HIPPA_ROUTE &&
    location.pathname !== LOGIN &&
    location.pathname !== FORGOT_PASSWORD &&
    location.pathname !== LIST_PRACTICE &&
    getBaseParam(location.pathname) !== getBaseParam(RESET_PASSWORD) &&
    location.pathname !== ALL_APPOINTMENTS;

  const doNotAddLatAndLngInRoute =
    getBaseParam(location.pathname) === getBaseParam(ACCOUNT_SECTION) ||
    location.pathname === SIGNUP;

  const {t} = useTranslation()

  useEffect(() => {
    if (shouldLoadGoogleScript) {
      if (!window.google) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&callback=initMap`
        );
        const latInQueryParams = +urlService.getQueryStringValue("lat");
        const lngInQueryParams = +urlService.getQueryStringValue("lng");
        let position;
        if (latInQueryParams && lngInQueryParams) {
          position = {
            latitude: latInQueryParams,
            longitude: lngInQueryParams,
          };
        }
        dispatch(setCurrentLocation(position));
      } else {
        setIsGoogleScriptLoaded(true);
      }
    }
  }, [location.pathname]);

  let currentLocationRef = useRef();
  currentLocationRef.current = currentLocation;

  useEffect(() => {
    if (
      navigator.permissions &&
      navigator.permissions.query({ name: "geolocation" })
    ) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          result.addEventListener("change", () => {
            Object.keys(currentLocationRef.current).length < 2 &&
              dispatch(setCurrentLocation());
          });
        });
    }
  }, []);

  const getCountryForUserLocation = async () => {
    dispatch(setIsGettingCurrentLocationInfo(true));
    const country = await getCountryFromLatLng({
      lat: currentLocation.latitude,
      lng: currentLocation.longitude,
    });
    dispatch(setIsGettingCurrentLocationInfo(false));
    dispatch(
      setCurrentLocationInfo({
        country,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      })
    );
  };

  useEffect(() => {
    if (
      isGoogleScriptLoaded &&
      currentLocation?.latitude &&
      currentLocation?.longitude
    ) {
      getCountryForUserLocation();
    }
  }, [
    isGoogleScriptLoaded,
    currentLocation?.latitude,
    currentLocation?.longitude,
  ]);

  useEffect(() => {
    if (
      shouldLoadGoogleScript &&
      !doNotAddLatAndLngInRoute &&
      isGoogleScriptLoaded &&
      currentLocationInfo?.latitude &&
      currentLocationInfo?.longitude
    ) {
      urlService.setQueryStringValue("lat", currentLocationInfo?.latitude);
      urlService.setQueryStringValue("lng", currentLocationInfo?.longitude);
    }
  }, [
    isGoogleScriptLoaded,
    currentLocationInfo?.latitude,
    currentLocationInfo?.longitude,
    location.pathname,
  ]);

  if (!shouldLoadGoogleScript) {
    return (
      <main className={classes.main}>
        {navigator ? <NetworkCheckLayer /> : null}
        {children}
      </main>
    );
  }

  return (
    <main className={classes.main}>
      {navigator ? <NetworkCheckLayer /> : null}
      {isGoogleScriptLoaded ? children : <CustomLoader />}
      {shouldLoadGoogleScript &&
        !doNotAddLatAndLngInRoute &&
        isGoogleScriptLoaded &&
        !isGettingCurrentLocationInfo &&
        !currentLocationInfo?.latitude &&
        !currentLocationInfo?.longitude &&
        !isLoadingCurrentLocation && (
          <CustomModal
            omitCloseButton
            open={
              shouldLoadGoogleScript &&
              isGoogleScriptLoaded &&
              !isGettingCurrentLocationInfo &&
              !currentLocationInfo?.latitude &&
              !currentLocationInfo?.longitude &&
              !isLoadingCurrentLocation
            }
            closeModalHandler={() => {}}
            icon={LocationPrompt}
            iconAltText={t("LOCATION_PERMISSION")}
            heading={t("LOCATION_MODAL_INSTRUCTION_TWO")}
            descriptionLine1={t("LOCATION_REQUIRED_TEXT")}
            hideButtons
            selectedLocation={selectedLocation}
            showLocationSearchComponent
          />
        )}
    </main>
  );
};

export default LocationWrapper;
