import {
  ALERT_USER,
  BORDER_GREY,
  DARK_BROWN,
  APP_PRIMARY_THEME_COLOR,
  DIM_GREY,
  ECLIPSE,
  GREY78,
  GREY91,
  APP_THEME_ONE,
  TEXT_RED,
  WHITE,
} from "../../consts/colors";

export const styles = {
  appBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0",
    padding: "0",
    minWidth: "100%",
    minHeight: "96px",
  },
  logoStyles: {
    width: "auto",
    height: "50px",
    cursor: "pointer",
    "@media (max-width : 700px)": {
      width: "auto",
      height: "33px",
    },
  },
  otherOptionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  fieldContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    marginRight: "50px",
  },
  iconStyles: {
    width: "24px",
    height: "24px",
    color: DIM_GREY,
  },
  activeHeader: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  fieldTextStyles: {
    color: DIM_GREY,
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
  },
  largerText: {
    minWidth: "9rem",
  },
  profileMenuContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
  },
  profileImageStyle: {
    height: "45px",
    width: "45px",
    marginRight: "1rem",
    borderRadius: "100%",
    objectFit: "cover",
  },
  defaultImageContainer: {
    position: "relative",
    width: "45px",
    height: "45px",
    marginRight: "16px",
    "@media (max-width: 600px)": {
      marginRight: "0",
    },
  },
  initialsImageCustomStyle: {
    width: "45px",
    height: "45px",
    position: "absolute",
  },
  initialsTextCustomStyle: {
    color: DARK_BROWN,
  },
  dropDownContainer: {
    position: "absolute",
    background: WHITE,
    boxShadow: "0px 0px 24px rgba(63, 58, 59, 0.12)",
    borderRadius: "6px",
    minWidth: "320px",
    maxWidth: "320px",
    top: "110%",
    right: "20px",
    paddingRight: "4px",
    "@media(max-width : 600px)": {
      right: "5px",
    },
  },
  dropDownItem: {
    paddingLeft: "0px",
    marginBottom: "5px",
    "&:hover": {
      backgroundColor: GREY91,
    },
    "@media (max-width: 900px)": {
      backgroundColor: "transparent",
      minHeight: "auto",
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  singleRow: {
    display: "flex",
    alignItems: "center",
    justifyContnet: "center",
    padding: "12px",
    gap: "8px",
    cursor: "default",
  },
  hideBox: {
    display: "none",
  },
  logoutBoxForMobileScreen: {
    padding: "0 12px",
  },
  fullWidthBox: {
    display: "flex",
    width: "100%",
  },
  halfBox: {
    width: "50%",
  },
  field: {
    border: `1px solid ${GREY78}`,
    borderRadius: "4px",
    padding: "4px 10px",
    width: "140px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    cursor: "pointer",
    "@media (max-width : 900px)": {
      width: "100%",
    },
  },
  mobileLogoutBox: {
    border: `1px solid ${TEXT_RED}`,
    "@media (max-width : 900px)": {
      width: "100%",
    },
  },
  fieldText: {
    color: DIM_GREY,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
  },
  mobileLogoutText: {
    color: TEXT_RED,
  },
  accountLogoutContainer: {
    display: "flex",
    alignItems: "center",
  },
  leftGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  menuListWrapper: {
    marginTop: "4px",
  },
  userInfoContainer: {
    marginBottom: "0.5rem",
    cursor: "default",
  },
  profileImageStyles: {
    width: "80px",
    height: "80px",
  },
  dropDownMainInfo: {
    color: ECLIPSE,
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "32px",
    marginBottom: "0.5rem",
    marginTop: "0.5rem",
    wordBreak: "break-word",
  },
  row: {
    display: "flex",
    alignItems: "start",
    gap: "5px",
    marginBottom: "0.5rem",
  },
  dropdownInfoIcon: {
    color: DIM_GREY,
    width: "15px",
    height: "15px",
  },
  dropdownInfoText: {
    color: DIM_GREY,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    wordBreak: "break-word",
  },
  actionItemContent: {
    display: "flex",
    alignItems: "center",
  },
  rowWithoutImage: {
    margin: "0 1rem",
  },
  actionItemImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionTextStyles: {
    color: ECLIPSE,
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "500",
    "@media (max-width : 900px)": {
      color: DIM_GREY,
      fontWeight: 700,
    },
  },
  muiIconStyles: {
    color: APP_THEME_ONE,
    width: "24px",
    height: "24px",
    "@media (max-width : 900px)": {
      color: DIM_GREY,
    },
  },
  lightIcon: {
    color: GREY78,
    width: "20px",
    height: "20px",
    "@media (max-width : 900px)": {
      color: DIM_GREY,
    },
  },
  logoutIcon: {
    width: "20px",
    height: "20px",
    color: TEXT_RED,
    "@media (max-width : 900px)": {
      color: TEXT_RED,
    },
  },
  lastDropDownItem: {
    marginBottom: "0px",
    color: ALERT_USER,
  },
  divider: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "4px",
    color: BORDER_GREY,
  },
  greetingText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: DIM_GREY,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  profileText: {
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "600",
    textTransform: "capitalize",
    marginRight: "4px",
    minWidth: "88px",
    color: ECLIPSE,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  logoutContainer: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    margin: "0 1.5rem 0 0.5rem",
  },
  logOutLoaderStyles: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  logoutHeaderIcon: {
    color: APP_PRIMARY_THEME_COLOR,
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  modalButtonStyle: {
    padding: "3%",
    width: "60%",
    backgroundColor: APP_PRIMARY_THEME_COLOR,
    "&:hover": {
      backgroundColor: APP_PRIMARY_THEME_COLOR,
    },
  },
  noBackgroundColor: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    marginRight: "1.5rem",
    "@media (max-width : 650px)": {
      gap: "4px",
      marginRight: "1rem",
    },
  },
  headingTextBox: {
    padding: "0 20px",
    borderRight: `1px solid ${DIM_GREY}`,
    "@media (max-width : 900px)": {
      display: "none",
    },
  },
  helpContainer: {
    paddingRight: "20px",
    paddingLeft:"0px"
  },
  middleBox: {
    paddingLeft: "0",
  },
  headerText: {
    color: DIM_GREY,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    cursor: "pointer",
  },
  btnContainer: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  slash: {
    color: DIM_GREY,
    fontWeight: 600,
  },
  btnStyles: {
    cursor: "pointer",
    color: DIM_GREY,
    fontWeight: 600,
  },
  menuIconBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  menuIcon: {
    color: DIM_GREY,
  },
  clickableIcon: {
    cursor: "pointer",
    width: "30px",
    height: "30px",
    color: APP_THEME_ONE,
  },
  brochureHeadingStyle: {
    fontSize: "18px",
    color: APP_THEME_ONE,
    textAlign: "left",
    fontWeight: 700,
    textTransform: "capitalize",
    margin: "12px",
  },
  pdfStyle: {
    height: "80vh",
    width: "100%",
    border: "none",
    "@media (max-width: 600px)": {
      height: "70vh",
    },
  },
  brochureContainer: {
    padding: "10px",

  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  headerNotLoginContainer: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
  },

}