import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import getToken from "../../Auth/getToken";
import { DASHBOARD } from "../../Auth/Routes";

const PublicRoutes = (props) => {
  const tokenCheck = getToken();
  const { loginRedirectRoute } = useSelector((state) => {
    return state.login;
  });

  if (tokenCheck) {
    return <Redirect to={loginRedirectRoute || DASHBOARD} />;
  }

  return props.component;
};

PublicRoutes.propTypes = {
  component: PropTypes.node,
};

export default PublicRoutes;
