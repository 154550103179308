import {
  WHITE,
  ORANGE_HEADING,
  THIN_GREY,
  BROWN_HEADING,
  APP_PRIMARY_THEME_COLOR,
} from "../../consts/colors";

export const styles = {
  modalContainer: {
    backgroundColor: WHITE,
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.12), 0px 6px 12px rgba(0, 0, 0, 0.12), 0px 1px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "2rem",
    paddingBottom: "1rem",
  },
  conditionalHeight: {
    minHeight: "60vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  closeIcon: {
    fill: BROWN_HEADING,
  },
  modalWarningImage: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  imageStyles: {
    height: "130px",
    width: "150px",
  },
  modalHeading: {
    color: APP_PRIMARY_THEME_COLOR,
    fontWeight: "600",
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "1rem",
  },
  modalDescription: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    marginBottom: "0.5rem",
    width: "50%",
  },
  locatonBoxContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
  },
  modalButtonContainer: {
    display: "flex",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    justifyContent: "center",
  },
  modalButtonItem: {
    display: "flex",
    justifyContent: "center",
  },
  modalButton: {
    fontSize: "20px",
    lineHeight: "27px",
    padding: "5%",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    borderRadius: "12px",
    width: "80%",
    height: "50px",
    "@media (max-width: 600px)": {
      width: "80%",
      fontSize: "15px",
      lineHeight: "22px",
    },
  },
  leaveButton: {
    fontWeight: 400,
    backgroundColor: APP_PRIMARY_THEME_COLOR,
    color: WHITE,
    "&:hover": {
      backgroundColor: APP_PRIMARY_THEME_COLOR,
    },
  },
  stayButton: {
    backgroundColor: WHITE,
    color: ORANGE_HEADING,
    border: `1px solid ${THIN_GREY}`,
    fontWeight: 400,
  },
};
