import {
  INFO_BLUE,
  VALIDATE_PASSWORD,
  WARNING_YELLOW,
} from "../../consts/colors";

export const styles = {
  successIcon: {
    color: VALIDATE_PASSWORD,
  },
  infoIcon: {
    color: INFO_BLUE,
  },
  warningIcon: {
    color: WARNING_YELLOW,
  },
};
