import React, { useState, useEffect, Suspense } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import "./App.css";
import moment from "moment";
import "moment/locale/es";
import DefaultRoute from "./components/RouteComponent/DefaultRoute";
import PublicRoutes from "./components/RouteComponent/PublicRoutes";
import PrivateRoute from "./components/RouteComponent/PrivateRoute";
import RedirectRoute from "./components/RouteComponent/RedirectRoute";
import LocationWrapper from "./components/LocationWrapper/LocationWrapper";
import Layout from "./components/Layout/Layout";
import CustomLoader from "./components/CustomLoader/CustomLoader";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {
  ROOT,
  SIGNUP,
  TERMS_AND_CONDITION,
  PRIVACY_POLICY_ROUTE,
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  DASHBOARD,
  CLINIC_DETAILS,
  PROVIDER_DETAILS,
  DEFAULT,
  BOOK_NEW_APPOINTMENT,
  ALL_APPOINTMENTS,
  APPOINTMENT_DETAILS,
  SPLASH,
  FAVORITES,
  SEARCH_LISTING,
  ACCOUNT_SECTION,
  LIST_PRACTICE,
  SUBMIT_FEEDBACK,
  USER_AGREEMENT,
} from "./Auth/Routes";
import getToken from "./Auth/getToken";
import { removeUserSignUpInformationFromLocalStorage } from "./containers/utils/removeUserSignupInformationFromLocalStoarge";
import { languageArray } from "./components/LanguageSelector/LanguageSelectorConstant";


const DefaultPage = React.lazy(() =>
  import("./containers/DefaultPage/DefaultPage")
);
const TermsAndConditions = React.lazy(() =>
  import("./containers/TermsAndConditions/TermsAndConditions")
);
const Login = React.lazy(() => import("./containers/Login/Login"));
const SignUp = React.lazy(() => import("./containers/SignUp/SignUp"));
const PrivacyPolicy = React.lazy(() =>
  import("./containers/TermsAndConditions/PrivacyPolicy/PrivacyPolicy")
);
const ForgotPassword = React.lazy(() =>
  import("./containers/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);
const ListYourPractice = React.lazy(() =>
  import("./containers/ListYourPractice/ListYourPractice")
);
const DashBoard = React.lazy(() => import("./containers/DashBoard/DashBoard"));
const ClinicDetails = React.lazy(() =>
  import("./containers/ClinicDetails/ClinicDetails")
);
const BookingFlow = React.lazy(() =>
  import("./containers/BookingFlow/BookingFlow")
);
const ProviderDetails = React.lazy(() =>
  import("./containers/ProviderDetails/ProviderDetails")
);
const Favorites = React.lazy(() => import("./containers/Favorites/Favorites"));
const SearchList = React.lazy(() =>
  import("./containers/SearchList/SearchList")
);
const AllAppointments = React.lazy(() =>
  import("./containers/AllAppointments/AllAppointments")
);
const AppointmentDetails = React.lazy(() =>
  import("./containers/AppointmentDetails/AppointmentDetails")
);
const AccountSection = React.lazy(() =>
  import("./containers/AccountSection/AccountSection")
);
const SubmitFeedback = React.lazy(() =>
  import("./containers/SubmitFeedback/SubmitFeedback")
);
const UserAgreement = React.lazy(() =>
  import("./containers/TermsAndConditions/UserAgreement/UserAgreement")
);

const App = ({ history }) => {
  const token = getToken();
  const [isUserLoggedIn, setIsUserLogedIn] = useState(Boolean(token));

  const isUserLoggedInFirstTime = useSelector(
    (state) => state.login.isLoginApiSuccess
  );
  const isLoading = useSelector((state) => {
    return state.userProfile.showUserProfileLoader;
  });

  const language = useSelector((state) => state.languageChange.language);
  const languageObj=languageArray.find((item)=>{
    return item.key===language;
  })

  moment.locale(languageObj?.locale)

  useEffect(() => {
    setIsUserLogedIn(Boolean(token));
    if (Boolean(token)) {
      removeUserSignUpInformationFromLocalStorage();
    }
  }, [token]);

  return (
    <Router history={history}>
      <Suspense fallback={<CustomLoader />}>
        {isLoading ? null : <Header />}
        <LocationWrapper>
          <Switch>
            <Route path={ROOT} exact>
              <DefaultRoute />
            </Route>
            <Route path={SPLASH} exact>
              <PublicRoutes component={<DefaultPage />} />
            </Route>
            <Route
              path={TERMS_AND_CONDITION}
              component={TermsAndConditions}
              exact
            />
            <Route
              path={PRIVACY_POLICY_ROUTE}
              component={PrivacyPolicy}
              exact
            />
            <Route path={USER_AGREEMENT} component={UserAgreement} exact />
            <Route path={SIGNUP} exact>
              <PublicRoutes component={<SignUp />} />
            </Route>
            <Route path={LOGIN} exact>
              <PublicRoutes component={<Login />} />
            </Route>
            <Route path={FORGOT_PASSWORD} exact>
              <PublicRoutes component={<ForgotPassword />} />
            </Route>
            <Route path={RESET_PASSWORD} exact>
              <PublicRoutes component={<ResetPassword />} />
            </Route>
            <Route path={LIST_PRACTICE} exact>
              <PublicRoutes component={<ListYourPractice />} />
            </Route>
            {isUserLoggedIn || isUserLoggedInFirstTime ? (
              <Layout>
                <Switch>
                  <Route path={DASHBOARD}>
                    <PrivateRoute component={<DashBoard />} />
                  </Route>
                  <Route path={FAVORITES}>
                    <PrivateRoute component={<Favorites />} />
                  </Route>
                  <Route path={CLINIC_DETAILS}>
                    <PrivateRoute component={<ClinicDetails />} />
                  </Route>
                  <Route path={SEARCH_LISTING}>
                    <PrivateRoute component={<SearchList />} />
                  </Route>
                  <Route path={BOOK_NEW_APPOINTMENT}>
                    <PrivateRoute component={<BookingFlow />} />
                  </Route>
                  <Route path={PROVIDER_DETAILS}>
                    <PrivateRoute component={<ProviderDetails />} />
                  </Route>
                  <Route path={ALL_APPOINTMENTS}>
                    <PrivateRoute component={<AllAppointments />} />
                  </Route>
                  <Route path={APPOINTMENT_DETAILS}>
                    <PrivateRoute component={<AppointmentDetails />} />
                  </Route>
                  <Route path={ACCOUNT_SECTION}>
                    <PrivateRoute component={<AccountSection />} />
                  </Route>
                  <Route path={SUBMIT_FEEDBACK}>
                    <PrivateRoute component={<SubmitFeedback />} />
                  </Route>
                  <Route path={DEFAULT}>
                    <Redirect to={DASHBOARD} />
                  </Route>
                </Switch>
              </Layout>
            ) : null}
            <Route path={PROVIDER_DETAILS} exact>
              <PublicRoutes component={<ProviderDetails />} />
            </Route>
            <Route path={CLINIC_DETAILS} exact>
              <PublicRoutes component={<ClinicDetails />} />
            </Route>
            <Route path={SUBMIT_FEEDBACK} exact>
              <PublicRoutes component={<SubmitFeedback />} />
            </Route>
            <Route path={DEFAULT}>
              <RedirectRoute />
            </Route>
          </Switch>
        </LocationWrapper>
        {isLoading ? null : <Footer />}
      </Suspense>
    </Router>
  );
};

export default App;
