const dev = {
  url: {
    API_URL: "https://staging-api.novadonticspms.com",
    VIDEO_CALL_URL: "https://staging-videocall.novadonticsfad.com/",
    APP_URL: "https://staging.mycarepulse.com",
  },
};
const prod = {
  url: {
    API_URL: "https://api.novadonticspms.com",
    VIDEO_CALL_URL: "https://videocall.novadonticsfad.com/",
    APP_URL: "https://mycarepulse.com",
  },
};
export const config = process.env.NODE_ENV === "production" ? prod : dev;
export const teamCarePulseEmail = "team@mycarepulse.com";
export const REVIEWS_ITEM_PER_PAGE = 5;
export const PAGINATED_ITEMS_PER_PAGE = 10;
export const MINIMUM_ITEMS_PER_PAGE = 5;
export const DEBOUNCING_WAIT_TIME = 300;
export const CAROUSEL_INTERVAL_DURATION = 4000;
export const SUCCESS_TIMEOUT_DURATION = 3000;
export const APPOINTMENT_ITEMS_PER_PAGE = 6;
export const CLINIC_PROFILE = "clinicProfile";
export const PROVIDER_PROFILE = "providerProfile";
export const VIDEO_CALL_APPOINTMENT_TAB = "Appointment Call";
export const CLINIC_APPOINTMENT_TAB = "Appointment Clinic";
export const SCHEDULED_APPOINTMENT_DETAILS = "ScheduledAppointmentDetails";
export const PAST_APPOINTMENT_DETAILS = "PastAppointmentDetails";
export const CLOSE_SUCCESS_MODAL = 1000;
export const MAX_WIDTH_SLOTS_MODAL = 1200;
export const EXTRA_LARGE_SCREEN_WIDTH = 1536;
export const FIRST_PAGE = 1;
export const LOCATION_MODAL_INSTRUCTION_ONE =
  "Allow us to access your location";
export const LOCATION_MODAL_INSTRUCTION_TWO =
  "Please enter an address in the field below";
export const questionToIdentifyNewPatient =
  "Have you seen this dentist before ?";
export const HAS_VISITED_THIS_DENTIST_BEFORE = "I have seen this doctor before";
export const HAS_NOT_VISITED_THIS_DENTIST_BEFORE = "I am a new patient";
export const PROFILE="Profile"
export const INSURANCELABEL="Insurance"
export const Family_Members="Family Members"
export const CLINIC_DETAILS='clinic-details'
export const PROVIDER_DETAILS="provider-details"
export const FEEDBACK_PAGE="feedbackPage"
export const PAGE='page';

export const initialCardDetail = {
  cardNumber: "",
  errorCardNumber: "",
  name: "",
  expiry: "",
  cardCode: "",
  isPrimary: false,
};

export const APPOINTMENT_TAB = {
  UPCOMING: "upcoming",
  PAST: "past",
  CANCELED: "canceled",
};

export const SEARCHED_TAB = {
  PROVIDERS: "providers",
  DENTAL_PRACTICES: "dental-practices",
};

export const MEETING_TYPE = {
  virtual: "Teledentistry",
  inOffice: "inOffice",
};
export const imageMimeType = /image\/(png|jpg|jpeg)/i;
export const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;
export const passwordLengthInstruction = "is longer than 7 characters.";
export const passwordCharacterInstruction =
  "contains at least one lowercase letter (a-z), one uppercase letter (A-Z) and one number.";

export const countryMobileCodes = [
  {
    name: "australia",
    code: "61",
    maxLength: 9,
  },
  {
    name: "canada",
    code: "1",
    maxLength: 10,
  },
  {
    name: "england",
    code: "44",
    maxLength: 10,
  },
  {
    name: "india",
    code: "91",
    maxLength: 10,
  },
  {
    name: "usa",
    code: "1",
    maxLength: 10,
  },
];
export const GENDER_LABEL_DESCRIPTION =
  "Gender is required for insurance matching. Please select a choice as listed in your health/ dental insurance records";

export const PHONE_NUMBER_LABEL_DESCRIPTION =
  "Phone number where the doctor can reach you";
export const SAME_APPOINTMENT_TIME =
  "Please change the date & time of this appointment or first cancel the existing appointment in this time slot. If you are trying to keep the same time slot but with a different provider, please first cancel the existing appointment, and then make a new appointment with the new provider in the time slot.";

export const API_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};
export const GLOBAL_SEARCH_PLACEHOLDER_TEXT =
  "Search by dentist name, practice name, or specialty";
export const SLOT_DURATION = "60 minutes";
export const FAD_APPOINTMENT_HELPLINE = "1.510.467.5268";
export const FAD_APPOINTMENT_HELPLINE_WITHOUT_COUNRY_CODE = "510.467.5268";
export const LISTBOX_PADDING = 8;
export const SLIDE_BY = 300;
export const LOCATION_REQUIRED_TEXT =
  "Determining your distance from dental offices requires location sharing or manually entering your location in the field below";

export const INOFFICE_APPOINTMENT_STEPS = ["Book Appointment", "Confirm"];
export const TELEDENTAL_APPOINTMENT_STEPS = [
  "Book Appointment",
  "Confirm",
  "Payment",
];
export const MIN_AGE = "User must be 16 years or older";
export const NEW_PATIENT_APPOINTMENTS_BLOCKED_BY_PATIENT =
  "* This provider does not accept new patient appointments for the selected office.";
export const CARE_PULSE_VIDEO_URL="https://s3.amazonaws.com/novadontics-uploads/mycarepulsefinal-1725427918852.mov"

 export  const getFrontendBaseUrl=()=>{
  switch (process.env.NODE_ENV) {
    case 'development':
      const localhostPort = window.location.port;
      return  `http://localhost:${localhostPort}`;
    case 'production':
      return 'https://staging.mycarepulse.com'
    default:
      return 'https://staging.mycarepulse.com'; 
  }
  }
