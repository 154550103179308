import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import history from "./history";
import reportWebVitals from "./reportWebVitals";
import theme from "../src/Assests/styles/theme";
import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material";
import { store } from "./Store/store";
import "./index.css";
import './i18.js'
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App history={history} />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();
