import { store } from "../Store/store";
import axios from "axios";
import getToken from "../Auth/getToken";
import logUserOut from "../Auth/logUserOut";
import { UNAUTHORIZED_USER } from "../Auth/statusCodes";
import { config } from "../consts/constant";

const axiosInstance = axios.create({
  baseURL: config.url.API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  request.headers["Content-Type"] = "application/json";
  request.headers["x-auth-token"] = getToken();
  request.headers["language"] = store.getState().languageChange.language;
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === UNAUTHORIZED_USER) {
      logUserOut();
    }
    return response;
  },
  (error) => {
    if (error.response?.status === UNAUTHORIZED_USER) {
      logUserOut();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
