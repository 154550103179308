import { store } from "../Store/store";
import axios from "axios";
import { config } from "../consts/constant";
const NovaAPI = axios.create({
  baseURL: config.url.API_URL,
});

NovaAPI.interceptors.request.use((request) => {
  request.headers["Language"]=store.getState().languageChange.language
  return request;
});

export default NovaAPI;
