import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { styles } from "./CustomNewModalStyles";

const CustomNewModal = ({
  children,
  closeModalHandler,
  fullWidth,
  includeCloseButton,
  maxWidth,
  modalContainerCustomStyles,
  omitCloseButton,
  openModal,
  placeCloseBtnOnExtremeRight,
}) => {
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp, false);
    return () => {
      window.removeEventListener("keyup", handleKeyUp, false);
    };
  }, []);

  const handleKeyUp = (e) => {
    const keys = {
      27: () => {
        e.preventDefault();
        if (omitCloseButton) {
          return;
        }
        closeModalHandler();
      },
    };
    if (keys[e.keyCode]) {
      keys[e.keyCode]();
    }
  };

  return (
    <>
      {openModal && (
        <Dialog
          maxWidth={maxWidth ? maxWidth : "md"}
          sx={{ ...styles.modal, ...modalContainerCustomStyles }}
          fullWidth={fullWidth}
          open={openModal}
        >
          {includeCloseButton ? (
            <Box sx={styles.buttonContainer}>
              <Button
                onClick={closeModalHandler}
                sx={{
                  ...(placeCloseBtnOnExtremeRight
                    ? styles.btnOnExtremeRightStyles
                    : {}),
                }}
              >
                <ClearIcon sx={styles.closeIcon} />
              </Button>
            </Box>
          ) : (
            ""
          )}
          {children}
        </Dialog>
      )}
    </>
  );
};

CustomNewModal.propTypes = {
  children: PropTypes.node,
  closeModalHandler: PropTypes.func,
  fullWidth: PropTypes.bool,
  includeCloseButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  modalContainerCustomStyles: PropTypes.object,
  omitCloseButton: PropTypes.bool,
  openModal: PropTypes.bool,
  placeCloseBtnOnExtremeRight: PropTypes.bool,
};

export default CustomNewModal;
