import React from "react";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";

import Chatbot from "../img/Chatbot.svg";
import myProfileImage from "../img/mobileTabIcons/myProfile.svg";
import myProfileSelected from "../img/mobileTabIcons/myProfileSelected.svg";
import familyMembersImage from "../img/mobileTabIcons/familyMembers.svg";
import familyMembersSelected from "../img/mobileTabIcons/familyMembersSeleted.svg";
import reviewIcon from "../img/mobileTabIcons/reviewIcon.svg";
import reviewIconSelected from "../img/mobileTabIcons/reviewIconSelected.svg";
import changePassword from "../img/mobileTabIcons/changePassword.svg";
import changePasswordSelected from "../img/mobileTabIcons/changePasswordSelected.svg";
import supportImage from "../img/mobileTabIcons/support.svg";
import supportSelectedImage from "../img/mobileTabIcons/supportSelected.svg";
import removeToken from "../Auth/removeToken";
import composeEmail from "../utils/composeEmail";
import {
  ACCOUNT_SECTION,
  ALL_APPOINTMENTS,
  DASHBOARD,
  FAVORITES,
  LIST_PRACTICE,
  LOGIN,
  SIGNUP,
} from "../Auth/Routes";
import {
  CHANGE_PASSWORD,
  INSURANCE,
  MY_FAMILY,
  MY_PROFILE,
  MY_REVIEWS,
  SUPPORT,
} from "./tabNames";
import {
  CARE_PULSE_BROCHURE,
  CONTACT_CARE_PULSE,
  teamCarePulseEmail,
} from "./constant";
import { Family_Members, INSURANCELABEL, PROFILE } from "./constant";

const MyProfile = React.lazy(() => import("../containers/MyProfile/MyProfile"));
const MyFamily = React.lazy(() => import("../containers/MyFamily/MyFamily"));
const MyReviews = React.lazy(() => import("../containers/MyReviews/MyReviews"));
const InsuranceUpload = React.lazy(() =>
  import("../containers/InsuranceUpload/InsuranceUpload")
);
const ChangePassword = React.lazy(() =>
  import("../components/ChangePassword/ChangePassword")
);
const Support = React.lazy(() => import("../components/Support/Support"));

export const accountSectionTabInfo = [
  {
    id: 1,
    tabNumber: 1,
    tabName: MY_PROFILE,
    tabLabel: PROFILE,
    imageSrc: myProfileImage,
    selectedImageSrc: myProfileSelected,
    children: MyProfile,
  },
  {
    id: 2,
    tabNumber: 2,
    tabName: INSURANCE,
    tabLabel:INSURANCELABEL,
    muiIcon: MedicalInformationOutlinedIcon,
    children: InsuranceUpload,
  },
  {
    id: 3,
    tabNumber: 3,
    tabName: MY_FAMILY,
    tabLabel: Family_Members,
    imageSrc: familyMembersImage,
    selectedImageSrc: familyMembersSelected,
    children: MyFamily,
  },
  {
    id: 4,
    tabNumber: 4,
    tabName: MY_REVIEWS,
    tabLabel: "My Reviews",
    imageSrc: reviewIcon,
    selectedImageSrc: reviewIconSelected,
    children: MyReviews,
  },
  {
    id: 5,
    tabNumber: 5,
    tabName: CHANGE_PASSWORD,
    tabLabel: "Change Password",
    imageSrc: changePassword,
    selectedImageSrc: changePasswordSelected,
    children: ChangePassword,
  },
  {
    id: 6,
    tabNumber: 6,
    tabName: SUPPORT,
    tabLabel: "Support",
    imageSrc: supportImage,
    selectedImageSrc: supportSelectedImage,
    children: Support,
  },
];

export const HEADER_ITEMS = [
  {
    id: 1,
    headerTitle: "Favorite",
    redirectPath: FAVORITES,
    largerText: false,
  },
  {
    id: 2,
    headerTitle: "My Appointments",
    redirectPath: ALL_APPOINTMENTS,
    largerText: true,
  },
];

export const ACTION_DROPDOWN_ITEMS = [
  {
    id: 1,
    useMUIHomeIcon: true,
    actionText: "Home Page",
    altText: "home-page",
    redirectPath: DASHBOARD,
  },
  {
    id: 2,
    useMUIFavIcon: true,
    actionText: "Favorite",
    altText: "favorites",
    redirectPath: FAVORITES,
    showOnSmallerScreen: true,
  },
  {
    id: 3,
    iconSrc: Chatbot,
    actionText: "My Appointments",
    altText: "appointments",
    redirectPath: ALL_APPOINTMENTS,
    showOnSmallerScreen: true,
  },
  {
    id: 4,
    useMUIReviewIcon: true,
    actionText: "My Reviews",
    altText: "reviews-img",
    redirectPath: `${ACCOUNT_SECTION}?tab=${MY_REVIEWS}`,
    tab: accountSectionTabInfo.find((item) => item.tabName === MY_REVIEWS)
      .tabNumber,
    showOnSmallerScreen: true,
  },
  {
    id: 5,
    useMUIAccountIcon: true,
    actionText: "My Profile",
    altText: "my-profile",
    redirectPath: `${ACCOUNT_SECTION}?tab=${MY_PROFILE}`,
    tab: accountSectionTabInfo.find((item) => item.tabName === MY_PROFILE)
      .tabNumber,
    showOnSmallerScreen: true,
  },
  {
    id: 8,
    useMuiInsurnanceIcon: true,
    actionText: "Insurance",
    altText: "insurance",
    redirectPath: `${ACCOUNT_SECTION}?tab=${INSURANCE}`,
    tab: accountSectionTabInfo.find((item) => item.tabName === INSURANCE)
      .tabNumber,
    hideOnSmallerScreen: true,
  },
  {
    id: 6,
    useMUIFamilyIcon: true,
    actionText: "Family Members",
    altText: "family-members",
    tab: accountSectionTabInfo.find((item) => item.tabName === MY_FAMILY)
      .tabNumber,
    redirectPath: `${ACCOUNT_SECTION}?tab=${MY_FAMILY}`,
    hideOnSmallerScreen: true,
  },
  {
    id: 7,
    useMUIReviewIcon: true,
    actionText: "My Reviews",
    altText: "reviews-img",
    redirectPath: `${ACCOUNT_SECTION}?tab=${MY_REVIEWS}`,
    tab: accountSectionTabInfo.find((item) => item.tabName === MY_REVIEWS)
      .tabNumber,
    hideOnSmallerScreen: true,
  },
  {
    id: 9,
    useMUIChangePassIcon: true,
    actionText: "Change Password",
    altText: "lock img",
    tab: accountSectionTabInfo.find((item) => item.tabName === CHANGE_PASSWORD)
      .tabNumber,
    redirectPath: `${ACCOUNT_SECTION}?tab=${CHANGE_PASSWORD}`,
    hideOnSmallerScreen: true,
  },
  {
    id: 10,
    useMUISupportIcon: true,
    actionText: "Support",
    altText: "support img",
    tab: accountSectionTabInfo.find((item) => item.tabName === SUPPORT)
      .tabNumber,
    redirectPath: `${ACCOUNT_SECTION}?tab=${SUPPORT}`,
    hideOnSmallerScreen: true,
  },
  {
    id: 11,
    useMUIEditAccountIcon: true,
    actionText: "Edit Account",
    altText: "account img",
    redirectPath: `${ACCOUNT_SECTION}?tab=${MY_PROFILE}`,
    tab: accountSectionTabInfo.find((item) => item.tabName === MY_PROFILE)
      .tabNumber,
    hideOnSmallerScreen: true,
  },
  {
    id: 12,
    useMUILogoutIcon: true,
    actionText: "Logout",
    altText: "logout img",
    onClickActionItem: () => removeToken(),
    showOnSmallerScreen: true,
  },
];

export const NON_LOGGED_IN_DROPDOWN = [
  {
    id: 1,
    noImage: true,
    actionText: "Sign up",
    redirectPath: SIGNUP,
    showDivider: true,
  },
  {
    id: 2,
    noImage: true,
    actionText: "Login",
    redirectPath: LOGIN,
    showDivider: true,
  },
  {
    id: 3,
    noImage: true,
    actionText: "List your practice on Care pulse",
    redirectPath: LIST_PRACTICE,
    showDivider: true,
  },
  {
    id: 4,
    noImage: true,
    actionText: "CONTACT_CARE_PULSE",
    onClickActionItem: () => composeEmail(teamCarePulseEmail),
    showDivider: true,
  },
  {
    id: 5,
    noImage: true,
    actionText: "CARE_PULSE_BROCHURE",
    onClickActionItem: () => {},
  },
];
