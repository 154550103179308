import { METALLIC_SILVER, SILVER_GREY, WHITE } from "../../consts/colors";

export const styles = {
  modalSearchTrayContainer: {
    position: "relative",
    width: "50%",
  },
  modalSearchTray: {
    width: "100%",
  },
  locationSearchRoot: {
    position: "relative",
  },
  endIconStyles: {
    padding: "0",
  },
  searchInputAndTrayContainer: {
    position: "relative",
  },
  locationDescription: {
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: 500,
  },
  rootContainer: {
    width: "100%",
  },
  searchTray: {
    position: "absolute",
    marginTop: "10px",
    zIndex: 2,
    width: "inherit",
    background: WHITE,
    maxHeight: "206px",
    overflow: "auto",
    borderRadius: "6px",
    boxShadow: "-2px 9px 25px 2px rgb(64 102 147 / 23%)",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      width: "7px",
      borderRadius: "12px",
      background: SILVER_GREY,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "12px",
      width: "7px",
      background: METALLIC_SILVER,
    },
  },
  modalSearchTrayPosition: {
    position: "inherit",
    maxHeight: "20vh",
  },
};
