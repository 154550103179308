import { BROWN_HEADING } from "../../consts/colors";
import { commonStyles } from "../../Assests/styles/commonStyles.js";

export const styles = {
  modal: {
    "& .MuiDialog-container .MuiPaper-root": {
      borderRadius: "12px",
      overflowX: "hidden",
      ...commonStyles.customSrollBar,
    },
  },
  buttonContainer: {
    position: "absolute",
    right: "0",
    top: "2%",
  },
  btnOnExtremeRightStyles: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "0",
    minWidth: "auto",
  },
  closeIcon: {
    fill: BROWN_HEADING,
  },
};
