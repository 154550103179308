import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  authoriseNetDetails: {},
};

const setAuthoriseNetPaymentDetails = (state, action) => {
  return updateObject(state, {
    authoriseNetDetails: action.payload || {},
  });
};

const resetAuthoriseNetPaymentDetails = (state, action) => {
  return updateObject(state, {
    authoriseNetDetails: {},
  });
};

const authoriseNetPaymentReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_AUTHORISE_NET_PAYMENT_CREDENTIALS:
      return setAuthoriseNetPaymentDetails(state, action);
    case types.RESET_AUTHORIZE_NET_CREDENTIALS:
      return resetAuthoriseNetPaymentDetails(state, action);
    default:
      return state;
  }
};

export default authoriseNetPaymentReducers;
