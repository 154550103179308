import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isSubmittingTheReview: false,
  errorWhileSubmittingReview: "",
  isReviewSubmittedSuccessFully: false,
};

const setIsSubmittingReview = (state, action) => {
  return updateObject(state, {
    isSubmittingTheReview: action.payload,
  });
};

const setErrorWhileSubmittingReview = (state, action) => {
  return updateObject(state, {
    errorWhileSubmittingReview: action.payload,
  });
};

const setReviewSubmittedSuccessFully = (state, action) => {
  return updateObject(state, {
    isReviewSubmittedSuccessFully: action.payload,
  });
};

const patientReviewReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.IS_SUBMITTING_THE_REVIEW:
      return setIsSubmittingReview(state, action);
    case types.IS_ERROR_SUBMITTING_REVIEW:
      return setErrorWhileSubmittingReview(state, action);
    case types.IS_REVIEW_SUBMITTED_SUCCESSFULLY:
      return setReviewSubmittedSuccessFully(state, action);
    default:
      return state;
  }
};

export default patientReviewReducers;
