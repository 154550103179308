import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Popover, Typography } from "@mui/material";
import { useWindowDimension } from "../../CustomHooks/useWindowDimension";
import {changeLanguage} from '../../Store/LanguageReducer/languageAction'
import { setItem } from "../../Auth/localStorageService";
import { languageArray } from "./LanguageSelectorConstant";
import { styles } from "./LanguageSelectorStyles";

export const LanguageSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.languageChange.language);
  const [anchorEl, setAnchorEl] = useState(null);
  const [width] = useWindowDimension();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange =  (language) => {
    dispatch(changeLanguage(language));
    setItem("language", language);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const selected = languageArray.find((item) => item.key === language);

  return (
    <Box>
      <Box style={styles.outerContainer} onClick={handleClick}>
          <Typography
            component={"img"}
            src={selected.flagImage}
            style={styles.languageSelector}
          />
        <Typography style={styles.shortText}>{language}</Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={
          width <= 900
            ? {
                sx: styles.paperStyles,
              }
            : {}
        }
      >
        <Box>
          {languageArray.map(({ key, label, flagImage }) => (
            <Box
              key={key}
              sx={styles.languageContainer}
              onClick={() => {
                handleLanguageChange(key);
              }}
            >
              <Typography
                component="img"
                src={flagImage}
                alt={`${label} flag`}
                style={styles.flagImage}
              />
              <Typography style={styles.LanguageText}>{label}</Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
