import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  toastDetails: {},
};

const setToastDetails = (state, action) => {
  return updateObject(state, {
    toastDetails: action.payload,
  });
};

const resetToastDetails = (state, action) => {
  return updateObject(state, {
    toastDetails: {},
  });
};

const toastDetailsReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.SET_TOAST_DETAILS:
      return setToastDetails(state, action);
    case types.RESET_TOAST_DETAILS:
      return resetToastDetails(state, action);
    default:
      return state;
  }
};

export default toastDetailsReducers;
