export const GLOBAL_SEARCH = " GLOBAL_SEARCH";
export const IS_LOADING_SEARCH_RESULTS = " IS_LOADING_SEARCH_RESULTS";
export const EMPTY_SEARCH_RESULTS = " EMPTY_SEARCH_RESULTS";

// --------------Current Location Actions-------------------------

export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const DELETE_CURRENT_LOCATION = "DELETE_CURRENT_LOCATION";
export const IS_LOADING_CURRENT_LOCATION = "IS_LOADING_CURRENT_LOCATION";
export const IS_ERROR_CURRENT_LOCATION = "IS_ERROR_CURRENT_LOCATION";
export const CURRENT_LOCATION_INFO = "CURRENT_LOCATION_INFO";
export const IS_GETTING_CURRENT_LOCATION_INFO =
  "IS_GETTING_CURRENT_LOCATION_INFO";

// -------------------- SELECTED LOCATION ACTIONS------------------

export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const DELETE_SELECTED_LOCATION = "DELETE_SELECTED_LOCATION";
