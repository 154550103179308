import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

import { styles } from "./HeaderStyles";

const HeaderItem = ({
  headerTitle,
  imgSrc,
  isImageView,
  isLargerText,
  isSelected,
  MuiIcon,
  onClick,
  selectedImgSrc,
}) => {
  return (
    <Box sx={styles.fieldContainer} onClick={onClick}>
      {isImageView ? (
        isSelected ? (
          <Typography
            component="img"
            src={selectedImgSrc}
            sx={{
              ...styles.iconStyles,
            }}
            alt="active-header-img"
          />
        ) : (
          <Typography
            component="img"
            src={imgSrc}
            sx={{
              ...styles.iconStyles,
            }}
            alt="header-img"
          />
        )
      ) : (
        <MuiIcon
          sx={{
            ...styles.iconStyles,
            ...(isSelected ? styles.activeHeader : {}),
          }}
        />
      )}
      <Typography
        sx={{
          ...styles.fieldTextStyles,
          ...(isSelected ? styles.activeHeader : {}),
          ...(isLargerText ? styles.largerText : {}),
        }}
      >
        {headerTitle}
      </Typography>
    </Box>
  );
};

HeaderItem.propTypes = {
  imgSrc: PropTypes.node,
  isImageView: PropTypes.bool,
  isLargerText: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  selectedImgSrc: PropTypes.node,
};

export default HeaderItem;
