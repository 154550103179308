import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isResetPasswordApiError: false,
  renderResetPasswordFormState: false,
  renderErrorScreen: false,
  showLoader: false,
  isRenderCongrtulationScreen: false,
};

const setIsResetPasswordApiError = (state, action) => {
  return updateObject(state, {
    isResetPasswordApiError: action.payload,
  });
};

const setRenderResetPasswordFormState = (state, action) => {
  return updateObject(state, {
    renderResetPasswordFormState: action.payload,
  });
};

const setRenderErrorScreen = (state, action) => {
  return updateObject(state, {
    renderErrorScreen: action.payload,
  });
};

const setShowLoader = (state, action) => {
  return updateObject(state, {
    showLoader: action.payload,
  });
};

const setRenderCongratulationScreen = (state, action) => {
  return updateObject(state, {
    isRenderCongrtulationScreen: action.payload,
  });
};

const resetPasswordReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.RESET_PASSWORD_API_ERROR:
      return setIsResetPasswordApiError(state, action);
    case types.RENDER_RESET_PASSWORD_FORM:
      return setRenderResetPasswordFormState(state, action);
    case types.RENDER_ERROR_SCREEN:
      return setRenderErrorScreen(state, action);
    case types.SHOW_LOADER:
      return setShowLoader(state, action);
    case types.RENDER_CONGRATULATION_SCREEN:
      return setRenderCongratulationScreen(state, action);
    default:
      return state;
  }
};
export default resetPasswordReducers;
