import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  isSendingEmail: false,
  emailSentSuccess: false,
  emailSentError: false,
  invalidEmailError: false,
  isAccountBlockedForgotPassError: "",
};

const setIsSendingEmail = (state, action) => {
  return updateObject(state, {
    isSendingEmail: action.payload,
  });
};

const setEmailSentSuccess = (state, action) => {
  return updateObject(state, {
    emailSentSuccess: action.payload,
  });
};

const setEmailSentError = (state, action) => {
  return updateObject(state, {
    emailSentError: action.payload,
  });
};

const setInvalidEmailError = (state, action) => {
  return updateObject(state, {
    invalidEmailError: action.payload,
  });
};

const setIsAccessDenied = (state, action) => {
  return updateObject(state, {
    isAccountBlockedForgotPassError: action.payload,
  });
};

const forgotPasswordReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.IS_SENDING_EMAIL:
      return setIsSendingEmail(state, action);
    case types.EMAIL_SENT_SUCCESS:
      return setEmailSentSuccess(state, action);
    case types.EMAIL_SENT_ERROR:
      return setEmailSentError(state, action);
    case types.INVALID_EMAIL_ERROR:
      return setInvalidEmailError(state, action);
    case types.IS_ACCOUNT_BLOCKED:
      return setIsAccessDenied(state, action);
    default:
      return state;
  }
};

export default forgotPasswordReducers;
