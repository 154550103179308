import * as types from "./types";
import { updateObject } from "../utility";

const initialState = {
  clinicsResponse: [],
  isLoadingClinicsResults: false,
  isErrorGettingClinics: false,
  clinicsTotalCount: 0,
  specialitiesResponse: [],
  providersResponse: null,
  isLoadingProviderResults: false,
  isErrorGettingProviders: false,
  providerTotalCount: 0,
  bookAppointmentDetails: {},
  slotSelectedFromListingPage: {},
};

const setClinicsResponse = (state, action) => {
  return updateObject(state, {
    clinicsResponse: action.payload,
  });
};

const setSpecialitiesResponse = (state, action) => {
  return updateObject(state, {
    specialitiesResponse: action.payload,
  });
};

const getProviderTotalCount = (state, action) => {
  return updateObject(state, {
    providerTotalCount: action.payload,
  });
};

const setProvidersResponse = (state, action) => {
  return updateObject(state, {
    providersResponse: action.payload,
  });
};

const loadingProviderResults = (state, action) => {
  return updateObject(state, {
    isLoadingProviderResults: action.payload,
  });
};

const errorGettingProviders = (state, action) => {
  return updateObject(state, {
    isErrorGettingProviders: action.payload,
    providersResponse: null,
  });
};

const resetProviderResults = (state, action) => {
  return updateObject(state, {
    providerTotalCount: 0,
    isLoadingProviderResults: false,
    providersResponse: null,
  });
};

const loadingClinicsResults = (state, action) => {
  return updateObject(state, {
    isLoadingClinicsResults: action.payload,
  });
};

const errorGettingClinics = (state, action) => {
  return updateObject(state, {
    isErrorGettingClinics: action.payload,
    clinicsResponse: [],
  });
};

const resetClinicResults = (state, action) => {
  return updateObject(state, {
    clinicsTotalCount: 0,
    isLoadingClinicsResults: false,
    clinicsResponse: [],
  });
};

const getClinicTotalCount = (state, action) => {
  return updateObject(state, {
    clinicsTotalCount: action.payload,
  });
};

const setBookAppointmentDetails = (state, actions) => {
  return updateObject(state, {
    bookAppointmentDetails: actions.payload,
  });
};

const redirectToProviderPageWithSelectedSlot = (state, actions) => {
  return updateObject(state, {
    slotSelectedFromListingPage: actions.payload,
  });
};

const resetBookAppointmentDetails = (state, actions) => {
  return updateObject(state, {
    bookAppointmentDetails: {},
  });
};

const bookAnAppointmentReducers = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_CLINICS:
      return setClinicsResponse(state, action);
    case types.IS_LOADING_CLINIC_RESULTS:
      return loadingClinicsResults(state, action);
    case types.ERROR_GETTING_CLINICS:
      return errorGettingClinics(state, action);
    case types.GET_CLINICS_TOTAL_COUNT:
      return getClinicTotalCount(state, action);
    case types.RESET_CLINICS_RESULTS:
      return resetClinicResults(state, action);
    case types.GET_SPECIALITIES:
      return setSpecialitiesResponse(state, action);
    case types.GET_PROVIDERS:
      return setProvidersResponse(state, action);
    case types.IS_LOADING_PROVIDER_RESULTS:
      return loadingProviderResults(state, action);
    case types.ERROR_GETTING_PROVIDERS:
      return errorGettingProviders(state, action);
    case types.GET_PROVIDERS_TOTAL_COUNT:
      return getProviderTotalCount(state, action);
    case types.RESET_PROVIDER_RESULTS:
      return resetProviderResults(state, action);
    case types.SET_BOOK_APPOINTMENT_DETAILS:
      return setBookAppointmentDetails(state, action);
    case types.REDIRECT_TO_PROVIDER_PAGE_WITH_SELECTED_SLOT:
      return redirectToProviderPageWithSelectedSlot(state, action);
    case types.RESET_BOOK_APPOINTMENTS_DETAILS:
      return resetBookAppointmentDetails(state, action);
    default:
      return state;
  }
};

export default bookAnAppointmentReducers;
