import { ECLIPSE, GREY91 } from "../../consts/colors";

export const styles = {
  listItem: {
    height: "4rem",
    "&:hover": {
      background: GREY91,
    },
    "&:selected": {
      background: GREY91,
    },
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
  },
  listItemContainer: {
    paddingLeft: "2.5%",
  },
  lisItemText: {
    color: ECLIPSE,
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "22px",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 900px)": {
      fontSize: "14px",
    },
  },
};
