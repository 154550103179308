import * as types from "./types";
import NovaAPI from "../../Axios/NovaAPI";
import { setItem } from "../../Auth/localStorageService";
import { ACCESS_DENIED, DATA_SAVED_SUCCESSFULLY } from "../../Auth/statusCodes";
import {
  NETWORK_ERROR,
  NETWORK_ERROR_MESSAGE,
  loginErrorMessages,
} from "../../consts/errorMessages";

export const setUserSessionTimeOut = (payload) => {
  return {
    type: types.SESSION_TIME_OUT,
    payload,
  };
};

export const isSuccessfullyLoggedIn = (boolean) => {
  return {
    type: types.IS_USER_SUCCESSFULLY_LOGGED_IN,
    payload: boolean,
  };
};

export const setIsLoginApiFailed = (value) => {
  return {
    type: types.IS_LOGIN_API_FAILED,
    payload: value,
  };
};

export const setLoginErrorMessage = (value) => {
  return {
    type: types.LOGIN_ERROR_MESSAGE,
    payload: value,
  };
};

export const setInvalidCredentialsError = (value) => {
  return {
    type: types.INVALID_CREDENTIALS,
    payload: value,
  };
};

export const setLoginRedirectRoute = (value) => {
  return {
    type: types.LOGIN_REDIRECT_ROUTE,
    payload: value,
  };
};

export const redirectUserFromDefaultPage = (payload) => {
  return {
    type: types.REDIRECT_USER_FROM_DEFAULT_PAGE,
    payload,
  };
};

export const setNewUserIsRegistered = (payload) => {
  return {
    type: types.NEW_USER_IS_REGISTERED,
    payload,
  };
};

export const setAccountRestored = (payload) => {
  return {
    type: types.ACCOUNT_IS_RESTORED,
    payload,
  };
};

export const resetRedirectUserFromDefaultPage = (payload) => {
  return {
    type: types.RESET_REDIRECT_USER_FROM_DEFAULT_PAGE,
    payload,
  };
};

export const isSigningInUser = (payload) => {
  return {
    type: types.IS_SIGNING_IN_USER,
    payload,
  };
};

export const setLoggingOutUser = (payload) => {
  return {
    type: types.LOGGING_OUT_USER,
    payload,
  };
};

export const loginUser = (userDetails) => {
  return (dispatch) => {
    dispatch(setIsLoginApiFailed(false));
    dispatch(setInvalidCredentialsError(false));
    dispatch(setLoginErrorMessage(""));
    dispatch(setUserSessionTimeOut(false));
    dispatch(isSigningInUser(true));
    NovaAPI.put("/v4/session", userDetails, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === DATA_SAVED_SUCCESSFULLY) {
          dispatch({
            type: types.LOGIN_USER,
            payload: res.data,
          });
          setItem("loginToken", res.data.token);
          dispatch(isSuccessfullyLoggedIn(true));
          dispatch(isSigningInUser(false));
          dispatch(setNewUserIsRegistered(false));
          dispatch(setAccountRestored(false));
          return;
        }
        dispatch(isSigningInUser(false));
      })
      .catch((err) => {
        dispatch(isSigningInUser(false));
        if ((navigator && !navigator.onLine) || err.message === NETWORK_ERROR) {
          dispatch(setLoginErrorMessage(NETWORK_ERROR_MESSAGE));
          return;
        }
        if (
          err.response?.data?.message ===
            loginErrorMessages.WRONG_CREDENTIALS ||
          err.response?.data?.message === loginErrorMessages.NOT_AUTHORIZED
        ) {
          dispatch(setInvalidCredentialsError(true));
          return;
        }
        if (+err.response?.data?.errorCode === ACCESS_DENIED) {
          dispatch(setLoginErrorMessage(err.response?.data?.message));
          return;
        }
        dispatch(setIsLoginApiFailed(true));
        dispatch(isSuccessfullyLoggedIn(false));
      });
  };
};
