export const isArrayContainsValidValue = (arr) => {
  if (!arr || !arr?.length) {
    return false;
  }
  if (arr.length) {
    if (!arr[0] || (typeof arr[0] === "string" && arr[0] === "null")) {
      return false;
    }
  }
  return true;
};

export const getValidUrlParam = (url) => {
  if (url) {
    return url.replace(/ /g, "+");
  }
  return "";
};

export const getInitials = (name, endWithFullStop) => {
  if (name) {
    return `${name.slice(0, 1)}${endWithFullStop ? "." : ""}`;
  }
  return "";
};

export const getAppointmentType = (appointmentType) => {
  if (!appointmentType) return "";
  if (appointmentType === "inPatient") return "In-office";
  if (appointmentType === "virtual") return "Teledental";
  return appointmentType;
};

export const getTimeZone = (timeZone) => {
  if (!timeZone) return "";
  return timeZone?.trim()?.split(" ")?.[0];
};

export const metreToMiles = (metres) => {
  if (!metres) {
    return "";
  }
  return `${Math.round(metres / 1609)} mi`;
};

export const removeAllDelimeterOccurencesFromString = (str, delimeter = "") => {
  return str.split(`${delimeter}`).join("");
};

export const getUserCountry = (addressComponent) => {
  if (!addressComponent?.length) {
    return "";
  }
  for (let i = addressComponent.length - 1; i >= 0; i--) {
    if (addressComponent[i]?.types.includes("country")) {
      return addressComponent[i].long_name;
    }
  }
  return "";
};

export const getCountryFromLatLng = async ({ lat, lng }) => {
  let country = "";
  try {
    const latlng = {
      lat,
      lng,
    };
    if (window.google) {
      const res = await new window.google.maps.Geocoder().geocode({
        location: latlng,
      });
      country = getUserCountry(res.results[0]?.address_components);
    }
  } catch (err) {
    console.log(err);
  }
  return country;
};

export const areTwoNonNestedObjectsEqual = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();
  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  return obj1Keys.every((key) => {
    const obj1Value = obj1[key];
    const obj2Value = obj2[key];
    return obj1Value === obj2Value;
  });
};

export const areTwoArrayOfNonNestedObjectsEqual = (arr1, arr2) => {
  if (!arr1?.length && !arr2?.length) {
    return true;
  }
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  if (arr1?.length === arr2?.length) {
    let ctr = 0;
    arr1.forEach((array1Obj, idx) => {
      if (areTwoNonNestedObjectsEqual(array1Obj, arr2[idx])) {
        ctr++;
      }
    });
    return ctr === arr1?.length;
  }
};

export const returnTextWithValueIfValueExists = (text, value, valueToShow) => {
  return value ? `${text} ${valueToShow ? valueToShow : value}` : "";
};

export const groupDataAlphabetically = ({ data, keyName }) => {
  const groupedData = {};
  data?.forEach((item) => {
    const firstLetter = /[0-9]/.test(item?.[keyName]?.[0]?.toUpperCase())
      ? "0-9"
      : item?.[keyName]?.[0]?.toUpperCase();
    if (!groupedData[firstLetter]) {
      groupedData[firstLetter] = [];
    }
    groupedData[firstLetter].push(item);
  });
  return groupedData;
};
