import * as types from "./types";

export const setToastDetails = (payload) => {
  return {
    type: types.SET_TOAST_DETAILS,
    payload,
  };
};

export const resetToastDetails = () => {
  return {
    type: types.RESET_TOAST_DETAILS,
  };
};


