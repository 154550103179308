import { APP_PRIMARY_THEME_COLOR, GREY95, WHITE } from "../../consts/colors";

export const styles = {
  footer: {
    marginTop: "auto",
  },
  footerContainer: {
    background: "linear-gradient(95.56deg, #5F2465 5.7%, #886A8D 115.82%)",
    padding: "0 2rem",
  },
  rootContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "88px",
    "@media (max-width : 1200px)": {
      flexDirection: "column-reverse",
      gap: "1rem",
      padding: "1rem 0",
    },
  },
  copyRightColumnStyles: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    "@media (max-width : 1200px)": {
      marginBottom: "0.5rem",
    },
  },
  appIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  downloadLinksImages: {
    width: "125px",
    height: "38px",
    border: `1px solid ${GREY95}`,
    borderRadius: "4px",
  },
  divider: {
    width: "16px",
    height: "0px",
    opacity: 0.3,
    border: `1px solid ${WHITE}`,
    transform: "rotate(90deg)",
  },
  copyRightText: {
    color: WHITE,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    "@media(max-width : 600px)": {
      fontSize: "12px",
      lineHeight: "15px",
    },
  },
  mainLinksText: {
    "@media (max-width: 600px)": {
      fontSize: "12px",
      lineHeight: "15px",
      textAlign: "center",
    },
  },
  socialIconsBox: {
    display: "flex",
    gap: "0.75rem",
    alignItems: "center",
    justifyContent: "space-between",
    "@media (max-width: 1200px)": {
      gap: "1rem",
    },
  },
  socialIcons: {
    width: "27px",
    height: "27px",
    color: "transparent",
    "&.MuiSvgIcon-root": {
      stroke: "white",
      strokeWidth: 1,
    },
  },
};
