import { DIM_GREY } from "../../consts/colors";

export const styles = {
  languageContainer: {
    cursor: "pointer",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  languageSelector: {
    width: "30px",
    height: "30px",
  },
  flagImage: {
    height: "24px",
    width: "24px",
  },
  LanguageText: {
    fontSize: "14px",
    cursor: "pointer",
    color: DIM_GREY,
    fontWeight: 600,
  },
  shortText: {
    color: DIM_GREY,
    textAlign: "center",
    fontSize: "14px",
    textTransform: "uppercase",
  },
  outerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: "5px",
    cursor: "pointer",
    marginRight: "5px",
  },
  paperStyles: {
    top: "80px !important",
  },
};
